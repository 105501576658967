export const error_codes = {
    AUTH_ERROR: 1000010,
}

const state = {
    apiStatus: null,
    errorCode: null,
    errorMessage: null,
}

const getters = {
    apiStatus: state => {
        return state.apiStatus
    },
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setErrorCode(state, code) {
        state.errorCode = code
    },
    setErrorMessage(state, message) {
        state.errorMessage = message
    }
}

export default {
	namespaced: true,
	state,
    getters,
	mutations,
}