export const strings = {
    /*
     * スライダーメニュー
     */
    slider_top: 'トップページ',
    slider_floor: 'フロア',
    slider_sensor: '各種センサ等',
    slider_data: '分析・過去データ',
    slider_watch: '状態監視',
    slider_event: 'イベント',
    slider_password: 'パスワード変更',
    slider_logout: 'ログアウト',
}