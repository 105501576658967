import { getApi, OK } from '../util'

const state = {
    apiStatus: null,
    mstFloorMapFillColors: null,
    mstCategory: null,
    mstTemperatureLabel: null,
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setMstFloorMapFillColors(state, data) {
        state.mstFloorMapFillColors = data
    },
    setMstCategory(state, data) {
        state.mstCategory = data
    },
    setMstTemperatureLabel(state, data) {
        state.mstTemperatureLabel = data
    },
}

const actions = {
    // マスタ取得処理
    async getMstApi(context) {
        context.commit('setApiStatus', null)
        const response = await getApi('get_mst')
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setMstFloorMapFillColors', response.data.data.color_list)
            context.commit('setMstCategory',response.data.data.category)
            context.commit('setMstTemperatureLabel', response.data.data.temperature_label)
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}