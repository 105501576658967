<template>
    <div class="error-main">
        <div>
            <p>
                404
            </p>
            <p>
                該当のページが存在しません。
            </p>
            <button @click="event">
                トップに戻る
            </button>
        </div>
    </div>
</template>

<script>
import { route_path } from '../router'
export default {
    methods: {
        event() {
            this.$store.commit('error/setApiStatus', false)
            this.$router.push(route_path.top)
        }
    }
}
</script>

<style scoped>
.error-main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 1100;
}
.error-main div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.error-main div p {
    text-align: center;
    font-size: 30px;
}
.error-main div p:first-child {
    font-size: 40px;
    font-weight: bold;
}
.error-main div button {
    width: 100%;
    text-align: center;
    font-size: 30px;
}
</style>