import Vue from 'vue'
import Router from "vue-router";
import Congestion from "./components/Congestion.vue"
import Floor from "./components/Floor.vue"
import Report from "./components/Report.vue"
import FloorMap from "./components/FloorMap.vue"
import List from "./components/List.vue"
import Index from "./components/Index.vue"
import Event from "./components/Event.vue"
import EventRegister from "./components/EventRegister"
import Notfound from "./components/Notfound.vue"
import FloorUserCountApproximate from "./components/FloorUserCountApproximate.vue"
import Stay from "./components/Stay.vue"
import Seat from "./components/Seat.vue"
import Environment from "./components/Environment.vue"

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "*",
      name: "notfound",
      component: Notfound
    },
    {
      path: "/",
      name: "",
      component: Index
    },
    {
      path: "/congestion",
      name: "congestion",
      component: Congestion
    },
    {
      path: "/floor",
      name: "floor",
      component: Floor
    },
    {
        path: "/report",
        name: "report",
        component: Report
    },
    {
        path: "/floor_map",
        name: "floor_map",
        component: FloorMap
    },
    {
        path: "/list",
        name: "list",
        component: List
    },
    {
      path: "/event",
      name: "event",
      component: Event
    },
    {
      path: "/event_register",
      name: "event_register",
      component: EventRegister
    },
    {
      path: "/floor_user_count_approximate",
      name: "floor_user_count_approximate",
      component: FloorUserCountApproximate
    },
    {
      path: "/stay",
      name: "stay",
      component: Stay
    },
    {
      path: "/seat",
      name: "seat",
      component: Seat
    },
    {
      path: "/environment",
      name: "environment",
      component: Environment
    },
  ]
});

export const route_path = {
  top: '/',
  congestion: '/congestion',
  floor: '/floor',
  report: '/report',
  floor_map: '/floor_map',
  list: '/list',
  event: '/event',
  event_register: '/event_register',
  floor_user_count_approximate: '/floor_user_count_approximate',
  stay: '/stay',
  seat: '/seat',
  environment: '/environment',
}