<template>
	<div>
		<div class="row" style="margin-top: 20px">
			<div class="col-xs-12" :style="colXs12">
				<div class="col-xs-6" style="padding: 0 4px;" v-if="graph_data !== null">
					<div class="panel panel-info">
						<div class="panel-wrap-info">
							<div class="panel-heading main" style="font-size: 1.0em; height: 40px;line-height: 18px;">
								フロア環境情報
								<span class="pull-right clickable panel-toggle">
									<em class="fa fa-toggle-up"></em>
								</span>
							</div>
							<div class="panel-body">
								<div class="env_ttl">温度</div>
								<div class="env_val"><i :class="setTemperatureCircleClass(graph_data.floor_graph_data.temperature[graph_data.floor_graph_data.temperature.length - 1])"></i>{{roundUpTemperature(graph_data.floor_graph_data.temperature[graph_data.floor_graph_data.temperature.length - 1])}}℃</div>
								<div class="env_ttl">湿度</div>
								<div class="env_val">{{graph_data.floor_graph_data.humidity[graph_data.floor_graph_data.humidity.length - 1]}}％</div>
								<div class="env_g">
									<canvas class="main-chart" id="line-chart_env" height="200" width="240" />
								</div>
								<div class="co2_g">
									<canvas class="main-chart" id="line-chart_co2" height="200" width="240" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<template v-if="graph_data !== null">
					<template v-for="(item, index) in graph_data.floor_graph_data">
						<div v-if="index !== 'temperature' && index !== 'humidity' && index !== 'co2_concentration'" class="col-xs-6" style="padding: 0 4px;" :key="'graph_' + index">
							<div class="panel panel-info">
								<div class="panel-wrap-info">
									<div :class="'panel-heading room' + index" style="font-size: 1.0em; height: 40px;line-height: 18px;">
										{{ graph_labels[index] }}
										<span class="pull-right clickable panel-toggle">
											<em class="fa fa-toggle-up"></em>
										</span>
									</div>
									<div class="panel-body">
										<div class="env_g">
											<canvas class="main-chart" :id="'line-chart_' + index" height="200" width="240" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
				</template>
				<template v-if="graph_data !== null">
					<template v-for="(item, index) in graph_data.area_graph_data">
						<div class="col-xs-6" style="padding: 0 4px;" :key="'graph_' + index">
							<div class="panel panel-info">
								<div class="panel-wrap-info">
									<div :class="'panel-heading room' + index" style="font-size: 1.0em; height: 40px;line-height: 18px;">
										{{ item.area_name }}
										<span class="pull-right clickable panel-toggle">
											<em class="fa fa-toggle-up"></em>
										</span>
									</div>
									<div class="panel-body">
										<div v-if="item.humidity_flag == true" >
											<div class="env_g">
												<canvas class="main-chart" :id="'line-chart_area_env_' + index" height="200" width="240" />
											</div>
											<div class="count">
												<canvas class="main-chart" :id="'line-chart_area_count_' + index" height="200" width="240" />
											</div>
										</div>
										<div v-if="item.humidity_flag == false" >
											<div class="env_g">
												<canvas class="main-chart" :id="'line-chart_area_temperature_' + index" height="200" width="240" />
											</div>
											<div class="humant_count">
												<canvas class="main-chart" :id="'line-chart_area_human_count_' + index" height="200" width="240" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
				</template>
			</div>
		</div>
	</div>
</template>

<script>

import $ from 'jquery';
import { createGraph, graph_labels, GRAPH_TYPE } from '../config/graph';
import { mapState } from 'vuex';
import { floor_ids, setTemperatureCircleClass, roundUpTemperature } from '../config/const';
export default {
	data() {
		return {
			hSize: 0,
			now_date: null,
			floor: "",
			graph_data: null,
			graph_labels: graph_labels
		}
	},
	created() {
		this.floor = this.$route.query.floor
		this.getNowDate()
		this.getGraphData()
	},
	mounted() {
		this.hSize = window.innerHeight;
		$(document).on('click', '.panel-heading span.clickable', function(){
			var $this = $(this);
			if(!$this.hasClass('panel-collapsed')) {
				$this.parents('.panel').find('.panel-body').slideUp();
				$this.addClass('panel-collapsed');
				$this.find('em').removeClass('fa-toggle-up').addClass('fa-toggle-down');
			} else {
				$this.parents('.panel').find('.panel-body').slideDown();
				$this.removeClass('panel-collapsed');
				$this.find('em').removeClass('fa-toggle-down').addClass('fa-toggle-up');
			}
		})
	},
	methods: {
		getNowDate() {
			this.date = new Date()
			this.now_date = {
				year: this.date.getFullYear(),
				month: this.date.getUTCMonth() + 1,
				day: this.date.getDate(),
				hour: this.date.getHours(),
				minutes: this.date.getMinutes(),
			}
		},
		async getGraphData() {
			const params = {
				floor_id_list: [floor_ids[this.floor]],
			}
			await this.$store.dispatch('graph/getGraphApi', params)
			if (this.graphApiStatus) {
				Object.keys(this.graphData).forEach((key) => {
					this.graph_data = this.graphData[key]
				})
				this.$nextTick(() => {
					this.setGraphs()
				})
			}
		},
		setGraphs() {
			const times = this.graph_data.time
			let temperature_datas = [[], []]
			let co2_data = [[]]
			let graph_datas = [[]]
			// 温度、湿度、CO2濃度データ
			temperature_datas[0] = this.graph_data.floor_graph_data.temperature
			temperature_datas[1] = this.graph_data.floor_graph_data.humidity
			co2_data[0] = this.graph_data.floor_graph_data.co2_concentration

			// 温度、湿度、CO2濃度グラフ作成
			createGraph(GRAPH_TYPE.temperature_humidity, document.getElementById("line-chart_env"), temperature_datas, times, ["温度", "湿度"])
			createGraph(GRAPH_TYPE.co2, document.getElementById("line-chart_co2"), co2_data, times, ["CO2濃度"])

			// フロアの温度、湿度、CO2濃度以外
			Object.keys(this.graph_data.floor_graph_data).filter(key => {
				graph_datas[0] = this.graph_data.floor_graph_data[key]
				// 滞留人数
				if (key === 'count') {
					createGraph(GRAPH_TYPE.count, document.getElementById("line-chart_count"), graph_datas, times, ["滞留人数"])
				}
				// 照度
				else if (key === 'illuminance') {
					createGraph(GRAPH_TYPE.illuminance, document.getElementById("line-chart_illuminance"), graph_datas, times, ["照度"])
				}
				// 騒音
				else if (key === 'noise') {
					createGraph(GRAPH_TYPE.noise, document.getElementById("line-chart_noise"), graph_datas, times, ["騒音"])
				}
				// 総揮発性有機化学物量相当値
				else if (key === 'equivalent') {
					createGraph(GRAPH_TYPE.equivalent, document.getElementById("line-chart_equivalent"), graph_datas, times, ["総揮発性有機化学物量相当値"])
				}
				// 熱中症危険度指数
				else if (key === 'heat_stroke') {
					createGraph(GRAPH_TYPE.heat_stroke, document.getElementById("line-chart_heat_stroke"), graph_datas, times, ["熱中症危険度指数"])
				}
				// 入館人数
				else if (key === 'in_count') {
					createGraph(GRAPH_TYPE.in_count, document.getElementById("line-chart_in_count"), graph_datas, times, ["入館人数"])
				}
				// 退館人数
				else if (key === 'out_count') {
					createGraph(GRAPH_TYPE.out_count, document.getElementById("line-chart_out_count"), graph_datas, times, ["退館人数"])
				}
			})

			// エリアのデータ
			this.graph_data.area_graph_data.forEach((element,index) => {
				let temperature = element.data.map(value => value.temperature)
				let humidity = element.data.map(value => value.humidity)
				let count = element.data.map(value => value.count)
				let human_count = element.data.map(value => value.human_count)
				let humidity_flag = humidity.every(element => element === null)

				if (humidity_flag) {
					createGraph(GRAPH_TYPE.area_temperature, document.getElementById("line-chart_area_temperature_" + index), [temperature], times, [element.area_name + "(温度)"])
					createGraph(GRAPH_TYPE.area_human_count, document.getElementById("line-chart_area_human_count_" + index), [human_count], times, ["滞留人数"])
				} else {
					createGraph(GRAPH_TYPE.area_temperature_humidity, document.getElementById("line-chart_area_env_" + index), [temperature, humidity], times, [element.area_name + "(温度)", element.area_name + "(湿度)"])
					createGraph(GRAPH_TYPE.area_count, document.getElementById("line-chart_area_count_" + index), count, times, ["滞留人数"])
				}

			})
		},
		setTemperatureCircleClass(temperature) {
            return setTemperatureCircleClass(temperature, this.mstTemperatureLabel)
        },
		roundUpTemperature(temperature) {
            return roundUpTemperature(temperature)
        },
	},
	computed: {
		...mapState({
			graphApiStatus: state => state.graph.apiStatus,
			graphData: state => state.graph.graphData,
			floorDetailData: state => state.floor.floorDetailData,
			mstTemperatureLabel: state => state.master.mstTemperatureLabel,
		}),
		colXs12() {
			return {
				'--height': (this.hSize - 150) + 'px'
			}
		}
	},
	watch: {
		'$route' (to, from) {
			if (to !== from) {
				this.floor = to.query.floor
				this.getNowDate()
				this.getGraphData()
			}
		}
	}
}
</script>

<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/datepicker3.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/fp.css"></style>
<style scoped src="../assets/css/toastr.css"></style>
<style scoped src="../assets/css/neumorphism.css"></style>

<style scoped>
.col-sm-9.col-sm-offset-3.col-lg-10.col-lg-offset-2.main {
	width: 88%;
	--height: 0px;
	height: var(--height);
	margin-left: 12%;
}

.col-xs-7 {
	--height: 0px;
	height: var(--height);
	overflow-y: auto;
	padding-right: 2px;
	padding-left: 2px;
}

.div-alarm-style {
	--height: 0px;
	max-height: var(--height);
	overflow-y: auto;
}
</style>