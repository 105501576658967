<template>
	<div id="app">
		<error v-if="is_error" :code="errorCode" :message="errorMessage" />
		<navigation></navigation>
		<sidebar></sidebar>
		<router-view />
	</div>
</template>
<script>
import Sidebar from './components/Sidebar.vue';
import Navigation from './components/Navigation.vue';
import Error from './components/Error.vue'
import { mapState } from 'vuex';
import { error_codes } from './store/error';
import { logout } from './util';

export default {
	components: {
		Sidebar,
		Navigation,
		Error,
	},
	data() {
		return {
			is_error: false,
		}
	},
	mounted() {
		if (this.errorCode) {
			this.is_error = true
		}
		// 10秒ごとにアラートAPIを叩く
		setInterval(this.getAlert, 10000)
	},
	methods: {
		async getAlert() {
			await this.$store.dispatch('alert/getAlertApi')
			if (this.alertApiStatus) {
				let type = ""
				let font_color = "#FFFFFF"
				if (this.alertData !== undefined && this.alertData !== null) {
					this.alertData.filter(alert => {
						switch(alert.level) {
							case 1:
								type = "info"
								font_color = "#000000"
								break
							case 2:
								type = "warning"
								break
							case 3:
								type = "error"
								break
						}
						this.$toastr.Add({
							msg: alert.text,
							style: { backgroundColor: alert.color, color: font_color },
							type: type
						})
					})
				}
			}
		}
	},
	watch: {
		errorApiStatus(val) {
			if (this.errorCode === error_codes.AUTH_ERROR) {
				logout()
			}
			else {
				if (val) {
					this.is_error = true
				}
				else {
					this.is_error = false
				}
			}
		}
	},
	computed: {
		...mapState({
			alertApiStatus: state => state.alert.apiStatus,
			alertData: state => state.alert.alertData,
			errorCode: state => state.error.errorCode,
			errorMessage: state => state.error.errorMessage,
		}),
		errorApiStatus() {
			return this.$store.getters['error/apiStatus']
		},
	}
}
</script>

<style>
body {
	background: #f1f4f7;
	padding-top: 60px;
	font-size: 14px;
	color: #444444;
	font-family: 'Noto Sans JP', sans-serif;
	overflow: hidden;
}
</style>

<style src="./assets/css/tooltip.css"></style>
<style>
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700&amp;subset=japanese');
</style>