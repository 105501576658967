<template>
    <div class="col-xs-12 col-md-12" style="padding-left: 1px; padding-right: 1px;" :style="contentHight">
        <preloader-component color="blue" :scale="0.6" v-if="isShow"/>
        <div class="panel panel-default" v-if="stayCountApiStatus">  
            <div class="panel-body">
                <div class="floor-list" style="overflow-y: scroll; padding-bottom: 15px; padding-right: 15px" :style="floorListHeight">
                    <template v-for="(floorData, index) in stayCountData.floor_list">
                        <div :key="'table_header_' + index">
                            <h2>当日の滞留人数</h2>
                            <h2>{{index}}</h2>
                            <table :style="{'table-layout': 'fixed'}">
                                <thead>
                                    <tr>
                                        <th colspan="1">時間</th>
                                        <template v-for="(data, index) in floorData[0].area_data">
                                            <th v-if="data !== null" class="main" :key="'head_' + index">{{data.area_name}}</th>
                                        </template>
                                        <th class="main">合計</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(data, index) in floorData">
                                        <tr :key="'tr_' + index" v-if="Object.keys(data.area_data).length !== 0">
                                            <td v-if="data !== null" class="main">{{data.time}}</td>
                                            <template v-for="(data, index) in data.area_data">
                                                <td v-if="data !== null" class="main" :key="'td' + index">{{data.human_count}}</td>
                                            </template>
                                            <td class="main">{{ getTotal(data.area_data)}}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                     </template>
                </div> 
            </div>
        </div>
    </div>
</template>

<script scoped>
import { mapState } from "vuex";
import PreloaderComponent from "./PreloaderComponent.vue"

export default {
  components: {
		PreloaderComponent,
	},
  data() {
    return {
      hSize: 0,
      isShow: true,
    };
  },
  created() {
    this.getFloorData();
  },
  mounted() {
    this.hSize = window.innerHeight;
  },
  methods: {
    async getFloorData() {
      await this.$store.dispatch("floor/getStayCountForList");
      if (this.stayCountApiStatus) {
        this.isShow = false //ロード中のプログレスを隠す
      }
    },
    getTotal(data){
        let total = 0;
        data.filter((item) => {
          if (item.human_count !== null) {
              total += Number(item.human_count)
          }
        })
        return Math.round(total * 10) / 10
    }
  },
  computed: {
    contentHight() {
      return {
        "--height": this.hSize - 120 + "px",
      };
    },
    floorListHeight() {
      return {
        height: this.hSize - 180 + "px",
      };
    },
    ...mapState({
      stayCountApiStatus: (state) => state.floor.apiStatus,
      stayCountData: (state) => state.floor.stayCountForList,
    }),
  },
};
</script>

<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/fp.css"></style>
<style scoped src="../assets/css/neumorphism.css"></style>

<style scoped>
.panel {
  box-shadow: none;
}
::-webkit-scrollbar {
  height: 7px;
}
</style>
