import Vue from 'vue'
import Vuex from 'vuex'

import error from './error'
import master from './master'
import alert from './alert'
import top from './top'
import floor from './floor'
import userCheck from './userCheck'
import userCount from './userCount'
import graph from './graph'
import event from './event'
import environment from './environment'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		error,
		master,
		alert,
		top,
		floor,
		userCheck,
		userCount,
		graph,
		event,
		environment,
	}
})

export default store
