import { getApi, OK } from '../util'
import { build_id } from '../config/const'

const state = {
    apiStatus: null,
    buildingData: null,
    weatherData: null,
    eventData: null,
    floorData: null,
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setBuildingData(state, data) {
        state.buildingData = data
    },
    setWeatherData(state, data) {
        state.weatherData = data
    },
    setEventData(state, data) {
        state.eventData = data
    },
    setFloorData(state, data) {
        state.floorData = data
    },
}

const actions = {
    // トップデータ取得処理
    async getTopApi(context) {
        context.commit('setApiStatus', null)
        const response = await getApi('get_top', {build_id: build_id})
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setBuildingData', response.data.data.building_data)
            context.commit('setWeatherData', response.data.data.weather_data)
            context.commit('setEventData', response.data.data.event_list)
            return false
        }

        // 共通エラー処理
        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
    // トップフロアデータ取得処理
    async getTopFloorApi(context, data) {
        const params = {
            ...data,
            build_id: build_id
        }
        context.commit('setApiStatus', null)
        const response = await getApi('get_top_floor', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setFloorData', response.data.data)
            return false
        }

        if (!response.error){
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
        
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}