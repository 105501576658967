import { build_id } from '../config/const'
import { getApi, OK } from '../util'

const state = {
    apiStatus: null,
    userCountData: null,
    userCountDataByFloor: null,
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setUserCountData(state, data) {
        state.userCountData = data
    },
    setUserCountDataByFloor(state, data) {
        state.userCountDataByFloor = data;
    }
}

const actions = {
    // 人数カウント取得
    async getUserCountApi(context) {
        context.commit('setApiStatus', null)
        const response = await getApi('get_user_count', {build_id: build_id})
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setUserCountData', response.data.data.floor_list)
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
    // 人数カウント取得
    async getUserCountByFloorApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await getApi('get_user_count_by_floor', {
          build_id: build_id,
          floor_id: params.floor_id,
        });
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setUserCountDataByFloor', response.data.data.floor_list);
            return false
        }

        // 共通エラー処理
        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}