import { getApi, OK } from '../util'
import { build_id } from '../config/const'

const state = {
    apiStatus: null,
    graphData: null,
    shows: [true, true, true, true, true, true, true, true,],
}

const getters = {
    shows: state => {
        return state.shows
    },
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setGraphData(state, data) {
        state.graphData = data
    },
    setGraphShows(state, data) {
        state.shows = data
    },
}

const actions = {
    // フロアデータ取得
    async getGraphApi(context, data) {
        const params = {
            ...data,
            build_id: build_id,
        }
        context.commit('setApiStatus', null)
        const response = await getApi('get_graph_data', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setGraphData', response.data.data.floor)
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
}

export default {
	namespaced: true,
	state,
    getters,
	mutations,
	actions
}