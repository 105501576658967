<template>
	<div>
		<div class="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2 main" :style="contentHight">
			<div class="right-wrap" :style="rightWrap" style="overflow: scroll">
				<div class="row border_row">
					<ol class="breadcrumb">
						<!-- <li><a href="#"><em class="fa fa-home"></em></a></li> -->
						<li class="active">{{ floor }}</li>
					</ol>
					<div class="list-menu tabs">
						<ul role="tablist" class="nav nav-tabs">
							<li role="presentation" class="nav-item">
								<a :class="['nav-link', {active: activeTabIndex === 0}]" @click="tabClick(0)">リスト</a>
							</li>
							<!-- <li role="presentation" class="nav-item">
								<a :class="['nav-link', {active: activeTabIndex === 1}]" @click="tabClick(1)">グラフ</a>
							</li> -->
						</ul>
					</div>
				</div>
				<div v-if="activeTabIndex === 0">
					<MapDetail />
					<div class="col-xs-12 col-md-12" style="padding-left: 1px; padding-right: 1px;" :style="{'width': (floor_width > area_width ? floor_width : area_width) + 70 + 'px'}">
						<div class="panel panel-default">
							<div class="panel-body">
								<div class="floor-list" style="padding-bottom: 15px" v-if="floor_width > 0">
									<template v-for="(data, index) in userCountDataByFloor">
										<div :key="'table_header_' + index">
											<h4>{{ data.floor }}</h4>
											<table :style="{'table-layout': 'fixed', 'width': floor_width + 'px'}">
												<thead>
													<tr>
														<th :colspan="setColSpan(true, data.floor_data)" class="main">フロア</th>
													</tr>
													<tr>
														<th v-if="data.floor_data.temperature !== null" class="main">温度</th>
														<th v-if="data.floor_data.humidity !== null" class="main">湿度</th>
														<th v-if="data.floor_data.count !== null" class="main">滞留人数</th>
														<th v-if="data.floor_data.illuminance !== null" class="main">照度</th>
														<th v-if="data.floor_data.noise !== null" class="main">騒音</th>
														<th v-if="data.floor_data.co2_concentration !== null" class="main">CO2濃度</th>
														<th v-if="data.floor_data.equivalent !== null" class="main">総揮発性有機化学物量相当値</th>
														<th v-if="data.floor_data.heat_stroke !== null" class="main">熱中症危険度指数</th>
														<th v-if="data.floor_data.in_count !== null" class="main">入館人数</th>
														<th v-if="data.floor_data.out_count !== null" class="main">退館人数</th>
													</tr>
												</thead>
											</table>
										</div>
										<div class="floor-list-div" :key="'table_body_' + index">
											<table :style="{'table-layout': 'fixed', 'width': floor_width + 'px'}">
												<tbody>
													<tr>
														<!-- フロア -->
														<td :class="setTableClass(data.floor_data.temperature)" v-if="data.floor_data.temperature !== null"><i :class="['circle', setTemperatureCircleClass(data.floor_data.temperature.data)]"></i>{{ roundUpTemperature(data.floor_data.temperature.data) }}℃</td>
														<td :class="setTableClass(data.floor_data.humidity)" v-if="data.floor_data.humidity !== null">{{ data.floor_data.humidity.data }}%</td>
														<td :class="setTableClass(data.floor_data.count)" v-if="data.floor_data.count !== null">{{ data.floor_data.count.data }}人</td>
														<td :class="setTableClass(data.floor_data.illuminance)" v-if="data.floor_data.illuminance !== null">{{ data.floor_data.illuminance.data }}lx</td>
														<td :class="setTableClass(data.floor_data.noise)" v-if="data.floor_data.noise !== null">{{ data.floor_data.noise.data }}db</td>
														<!-- <td :class="setTableClass(data.floor_data.co2_concentration)" v-if="data.floor_data.co2_concentration !== null">{{ data.floor_data.co2_concentration.data }}ppm</td> -->
														<td v-if="data.floor_data.co2_concentration !== null">-</td>
														<td :class="setTableClass(data.floor_data.equivalent)" v-if="data.floor_data.equivalent !== null">{{ data.floor_data.equivalent.data }}ppb</td>
														<td :class="setTableClass(data.floor_data.heat_stroke)" v-if="data.floor_data.heat_stroke !== null">{{ data.floor_data.heat_stroke.data }}degC</td>
														<td :class="setTableClass(data.floor_data.in_count)" v-if="data.floor_data.in_count !== null">{{ data.floor_data.in_count.data }}人</td>
														<td :class="setTableClass(data.floor_data.out_count)" v-if="data.floor_data.out_count !== null">{{ data.floor_data.out_count.data }}人</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div :key="'table_header_' + index + 1">
											<table :style="{'table-layout': 'fixed', 'width': area_width + 'px'}">
												<thead>
													<tr>
														<template v-for="(area, area_index) in data.area_data">
															<th :colspan="setColSpan(false, area)" :class="'room' + (area_index + 1)" :key="'room' + area_index + '-head'">{{ area.name }}</th>
														</template>
													</tr>
													<tr>
														<template v-for="(area, area_index) in data.area_data">
															<th v-if="area.temperature !== null" :class="'room' + (area_index + 1)" :key="'room1' + area_index">温度</th>
															<th v-if="area.humidity !== null" :class="'room' + (area_index + 1)" :key="'room2' + area_index">湿度</th>
															<th v-if="area.count !== null" :class="'room' + (area_index + 1)" :key="'room3' + area_index">使用座席数</th>
															<th v-if="area.human_count !== null" :class="'room' + (area_index + 1)" :key="'room4' + area_index">滞留人数</th>
														</template>
													</tr>
												</thead>
											</table>
										</div>
										<div class="floor-list-div" :key="'table_body_' + index + 1">
											<table :style="{'table-layout': 'fixed', 'width': area_width + 'px'}">
												<tbody>
													<tr>
														<template v-for="(area, area_index) in data.area_data">
															<td :key="'area' + area_index + '_temperature'" v-if="area.temperature !== null" :class="setTableClass(area.temperature)"><i :class="['circle', setTemperatureCircleClass(area.temperature.data)]"></i>{{ roundUpTemperature(area.temperature.data) }}℃</td>
															<td :key="'area' + area_index + '_humidity'" v-if="area.humidity !== null" :class="setTableClass(area.humidity)">{{ area.humidity.data }}%</td>
															<td :key="'area' + area_index + '_count'" v-if="area.count !== null" :class="setCountTableClass(area.count)">{{ area.count.data }}</td>
															<td :key="'area' + area_index + '_human_count'" v-if="area.human_count !== null" :class="setCountTableClass(area.human_count)">{{ area.human_count.data }}人</td>
														</template>
													</tr>
												</tbody>
											</table>
										</div>
									</template>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else-if="activeTabIndex === 1">
					<FloorGraph />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MapDetail from './MapDetail.vue';
import FloorGraph from './FloorGraph.vue';
import {floor_ids, setTemperatureCircleClass, roundUpTemperature} from "../config/const";
import { mapState } from 'vuex';

export default {
	components: {
		MapDetail,
		FloorGraph
	},
	head: {
		title: function() { return { inner:  'Lumino - ' + this.floor } },
	},
	data() {
		return {
			hSize: 0,
			height: 0,
			activeTabIndex: 0,
			floor: "",
			floor_width: 0,
			area_width: 0,
			floor_ids: floor_ids,
		}
	},
	created() {
		this.floor = this.$route.query.floor
		this.getUserCountDataByFloor()
	},
	mounted() {
		this.hSize = window.innerHeight;
	},
	methods: {
		tabClick(index) {
			this.activeTabIndex = index
		},
		async getUserCountDataByFloor() {
			const params = {
				floor_id: floor_ids[this.$route.query.floor]
			}
			await this.$store.dispatch('userCount/getUserCountByFloorApi', params)
			if (this.userCountByFloorApiStatus) {
                // テーブルの全長を計算
                // 1カラム120px計算
                let floor_count = 0
                let area_count = 0
                this.userCountDataByFloor.filter(item => {
                    // フロアのカラム数を取得
                    Object.keys(item.floor_data).filter(key => {
                        if (item.floor_data[key] !== null) {
                            floor_count++
                        }
                    })
                    // エリアのカラム数を取得
                    item.area_data.filter((data) => {
                        Object.keys(data).filter(key => {
                            if (key !== 'category_id' && key !== 'name' && data[key] !== null) {
                                area_count++
                            }
                        })
                    })
                })
				this.floor_width = floor_count * 120
				this.area_width = area_count * 120
            }
		},
        // 閾値を上回っていた場合にabnormal or warningを返す
        setTableClass(data) {
            if (data === null) {
                return ""
            }
            let class_name = ""
            if (data.level === 1) {
                class_name = "warning"
            }
            else if (data.level === 2) {
                class_name = "abnormal"
            }
            return class_name
		},
		setCountTableClass(data) {
			if (data === null) {
				return ""
			}
			let class_name = ""
			if (data.level === 2) {
				class_name = "warning"
			}
			else if (data.level === 3) {
				class_name = "abnormal"
			}
			return class_name
		},
        setTemperatureCircleClass(temperature) {
            return setTemperatureCircleClass(temperature, this.mstTemperatureLabel)
        },
        roundUpTemperature(temperature) {
            return roundUpTemperature(temperature)
        },
        getCategoryName(category_id) {
            let name = ""
            this.mstCategory.filter((item) => {
                if (item.id === category_id) {
                    name = item.name
                }
            })
            return name
        },
        setColSpan(is_main, data) {
            let count = 0
            if (is_main) {
                Object.keys(data).filter(key => {
                    if (data[key] !== null) {
                        count++
                    }
                })
            }
            else {
                Object.keys(data).filter(key => {
                    if (data[key] !== null && key !== 'category_id' && key !== 'name') {
                        count++
                    }
                })
            }
            return count
        },
	},
	computed: {
		...mapState({
            mstCategory: state => state.master.mstCategory,
            mstTemperatureLabel: state => state.master.mstTemperatureLabel,
			selectedFloorId: state => state.floor.selectedFloorId,
            userCountByFloorApiStatus: state => state.userCount.apiStatus,
            userCountDataByFloor: state => state.userCount.userCountDataByFloor,
		}),
		contentHight() {
			return {
				'--height': (this.hSize - 60) + 'px'
			}
		},
		rightWrap() {
			return {
				'--height': (this.hSize - 100) + 'px'
			}
		},
	},
	watch: {
		'$route' (to, from) {
			if (to !== from) {
				this.floor = to.query.floor
				this.getUserCountDataByFloor()
			}
		}
	}
}
</script>

<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/tab_style.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/fp.css"></style>
<style scoped src="../assets/css/neumorphism.css"></style>
<style scoped>
.col-sm-9.col-sm-offset-3.col-lg-10.col-lg-offset-2.main {
	width: 88%;
	--height: 0px;
	height: var(--height);
	margin-left: 12%;
}

.border_row {
	margin: 0;
	border-bottom: 1px solid #dee2e6;
}

.right-wrap {
	--height: 0px;
	height: var(--height);
	border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    background: linear-gradient(to bottom right, #f0f2f5, #e9ecf2) !important;
    box-shadow: 5px 5px 5px #696969, -8px -8px 7px #ffffff;
    margin-top: 15px;
    margin-left: 20px;
}

.breadcrumb{
    float: left;
    margin-bottom: 0;
    line-height: 40px;
    height: 43px;
    width: 80%;
	padding: 0 20px;
}
.list-menu{
    border-left: 1px dashed #cdd2db;
    height: 40px;
    line-height: 40px;
    float: right;
    width: 20%;
    padding-left: 5px;
    font-size: 20px;
}
.list-menu em{
    line-height: 30px;
    width: 30px;
    text-align: center;
    border-radius: 30px;
    cursor: pointer;
    margin-right: 5px;
}

.list-menu em{
    background:linear-gradient(to bottom right, #d3d3d3, #c0c0c0) !important;
    box-shadow:#696969 3px 3px 5px, #ffffff -3px -3px 5px;
}

.list-menu em.active, .list-menu em:hover{
    background:linear-gradient(to bottom right, #c0c0c0, #d3d3d3) !important;
    box-shadow:#696969 3px 3px 5px inset, #ffffff -3px -3px 5px inset;
}
.list-menu em:first-child{
    margin-left: 5px;
}
</style>