/*
* ビルID
*/
export const build_id = process.env.VUE_APP_BUILD_ID

/*
* 人数データの項目タイプ
*/
export const list_data_type = {
    temperature: 0,
    humidity: 1,
    count: 2,
    illuminance: 3,
    noise: 4,
    co2_concentration: 5,
}

/*
 * 気温マーク名
 * abnormal: 赤丸
 * cold: 青丸
 */
const circle_temperature_name = {
    abnormal: 'abnormal',
    cold: 'cold',
}

/*
* 曜日一覧
*/
export const day_of_week = [
    "日",
    "月",
    "火",
    "水",
    "木",
    "金",
    "土"
]

export const floor_ids = {
    "B1": build_id + "B001",
    "B1F": build_id + "B001",
    "1F": build_id + "F001",
    "2F": build_id + "F002",
    "3F": build_id + "F003",
    "4F": build_id + "F004",
    "5F": build_id + "F005",
    "6F": build_id + "F006",
    "7F": build_id + "F007",
    "8F": build_id + "F008",
    "9F": build_id + "F009",
    "10F": build_id + "F010",
    "11F": build_id + "F011",
    "12F": build_id + "F012",
    "13F": build_id + "F013",
    "14F": build_id + "F014",
    "15F": build_id + "F015",
    "16F": build_id + "F016",
    "17F": build_id + "F017",
    "18F": build_id + "F018",
    "19F": build_id + "F019",
    "20F": build_id + "F020",
};

/*
 * 配列を指定数区切り
 */
export const sliceByNumber = (array, number) => {
    const length = Math.ceil(array.length / number)
    return new Array(length).fill().map((_, i) =>
        array.slice(i * number, (i + 1) * number)
    )
}

/*
 * 温度マークのクラス設定
 */
export function setTemperatureCircleClass(temperature, mstTemperatureLabel) {
    let circle_class_name = ""
    // 温度30℃以上
    if (temperature >= mstTemperatureLabel.abnormal) {
        circle_class_name = circle_temperature_name.abnormal
    }
    // 温度20℃以下
    else if (temperature <= mstTemperatureLabel.cold) {
        circle_class_name = circle_temperature_name.cold
    }

    return circle_class_name
}

/*
 * レポート画面、曜日フィルター用ラベル
 */
export const report_filter_labels = [
    "日曜日",
    "月曜日",
    "火曜日",
    "水曜日",
    "木曜日",
    "金曜日",
    "土曜日",
    "祝日",
]

/*
 * 小数点以下に切り上げ
 */
export function roundUpTemperature(temperature, decimal = 1) {
    if (!isNaN(temperature)) {
        temperature = parseFloat(temperature)
    } else {
        return 0
    }
    return (
        Math.round((temperature + Number.EPSILON) * Math.pow(10, decimal)) /
        Math.pow(10, decimal)
    );
}

export const area_bubbles = {
    // B1
    "101B001003": "bbl01", // 店舗103
    "101B001002": "bbl02", // CONO SPICE
    "101B001004": "bbl03", // B1F ロビー
    "101B001006": "bbl04", // B1F 廊下2
    "101B001005": "bbl05", // 授乳室（個室）
    "101B001009": "bbl06", // B1F ELVホール
    "101B001001": "bbl07", // Kitsch de deli
    "101B001007": "bbl08", // 喫煙室
    "101B001008": "bbl09", // B1F 廊下1
    // 1F
    "101F001002": "f1bbl01", // 店舗0102
    "101F001001": "f1bbl02", // 店舗0101 ESPRIT
    "101F001003": "f1bbl03", // 店舗0104 CRAFT BEER KOYOEN
    "101F001005": "f1bbl04", // 風除室1
    "101F001004": "f1bbl05", // 風除室2
    "101F001007": "f1bbl06", // 商業ELVホール
    "101F001006": "f1bbl07", // ELVホール前廊下
    "101F001008": "f1bbl08", // ELVホール
    // 2F
    "101F002002": "f2bbl01", // LIFORKプチラウンジ
    "101F002001": "f2bbl02", // ネクスタワーカーズラウンジ
    "101F002003": "f2bbl03", // ROOM H
    "101F002008": "f2bbl04", // テラス
    // 3F
    "101F003001": "f3bbl01", // 喫煙室
    // 20F
    "101F020001": "f20bbl01", // 喫煙室
    "101F020003": "f20bbl02", // スカイテラス
};