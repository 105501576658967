import { render, staticRenderFns } from "./List.vue?vue&type=template&id=59e90475&scoped=true&"
import script from "./List.vue?vue&type=script&scoped=true&lang=js&"
export * from "./List.vue?vue&type=script&scoped=true&lang=js&"
import style0 from "../assets/css/bootstrap.css?vue&type=style&index=0&id=59e90475&scoped=true&lang=css&"
import style1 from "../assets/css/tab_style.css?vue&type=style&index=1&id=59e90475&scoped=true&lang=css&"
import style2 from "./List.vue?vue&type=style&index=2&id=59e90475&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59e90475",
  null
  
)

export default component.exports