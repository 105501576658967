<template>
	<div class="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2 main" :style="contentHight">
		<div class="right-wrap" :style="rightWrap">
			<div class="kanrisystmbigbx">
				<div class="knrmainbx">
					<h1>イベント情報管理</h1>
					<div class="knrinbx">
						<h2 v-if="$route.query.edit">イベント更新</h2>
						<h2 v-else>イベント新規登録</h2>
						<div class="knrformbx" :style="formWrap" style="overflow-y: auto;">
							<div class="knrfmcon01">
								<dl>
									<dt>イベント名</dt>
									<dd>
										<div class="form">
											<span class="validation_text" v-if="validation_errors.name">イベント名を入力してください</span>
											<input type="text" name="eventname" v-model="params.name" >
										</div>
										<label>
											重要<input type="checkbox" class="chkbx" v-model="params.important_flag">
										</label>
									</dd>
								</dl>
							</div>
							<div class="knrfmcon01">
								<dl>
									<dt>イベント詳細</dt>
									<div class="form">
										<span class="validation_text" v-if="validation_errors.detail">イベント詳細を入力してください</span>
										<dd><textarea name="eventdtl" rows="10" style="resize: none;" v-model="params.detail"></textarea></dd>
									</div>
								</dl>
							</div>
							<div class="knrfmcon02 itemflex">
								<dl>
									<dt>開催日</dt>
									<div class="form">
										<span class="validation_text" v-if="validation_errors.start_date">開催日を入力してください</span>
										<span class="validation_text" v-if="validation_errors.start_date2">開催日は今日以降の日付を入力してください</span>
										<dd><input type="date" name="eventstrt" v-model="params.start_date"></dd>
									</div>
								</dl>
								<dl>
									<dt>開催時間</dt>
									<div class="form">
										<span class="validation_text" v-if="validation_errors.start_time">開催時間を入力してください</span>
										<span class="validation_text" v-if="validation_errors.start_time2">開催時間は現在時刻以降の時間を入力してください</span>
										<dd><input type="time" name="evstrttime" v-model="params.start_time"></dd>
									</div>
								</dl>
							</div>
							<div class="knrfmcon02 itemflex">
								<dl>
									<dt>終了日</dt>
									<div class="form">
										<span class="validation_text" v-if="validation_errors.end_date">終了日を入力してください</span>
										<span class="validation_text" v-if="validation_errors.end_date2">終了日は開催日以降の日付を入力してください</span>
										<dd><input type="date" name="eventend" v-model="params.end_date"></dd>
									</div>
								</dl>
								<dl>
									<dt>終了時間</dt>
									<div class="form">
										<span class="validation_text" v-if="validation_errors.end_time">終了時間を入力してください</span>
										<span class="validation_text" v-if="validation_errors.end_time2">終了時間は開催日時以降の時間を入力してください</span>
										<dd><input type="time" name="evendtime" v-model="params.end_time"></dd>
									</div>
								</dl>
							</div>
							<div class="knrfmcon02">
								<dl>
									<dt>対応ビル名</dt>
									<div class="form">
										<span class="validation_text" v-if="validation_errors.building_name">対応ビル名を選択してください</span>
										<dd>
											<select name="eventbuil" id="buil-select" v-model="params.building_name">
												<option value="">ビルを選択</option>
												<option value="UN名古屋ネクスタ">UN名古屋ネクスタ</option>
												<option value="UN名古屋">UN名古屋</option>
												<option value="ブロッサ">ブロッサ</option>
												<option value="全施設">全施設</option>
											</select>
										</dd>
									</div>
								</dl>
							</div>
							<div class="formbtnbx lastelement">
								<button class="prevbtn"><router-link :to="route_path.event" class="btn lastelement">戻る</router-link></button>
								<button class="rgstbtn02" @click="checkValidation"><span class="btn lastelement">登録</span></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script scoped>
import { mapState } from 'vuex'
import { route_path } from '../router'
export default {
	head: {
		title: function() {
			return {
				inner:  'Lumino - イベント登録'
			}
		}
	},
	data() {
		return {
			route_path: route_path,
			hSize: 0,
			params: {
				name: "",
				detail: "",
				start_date: "",
				start_time: "",
				end_date: "",
				end_time: "",
				building_name: "",
				important_flag: false,
			},
			validation_errors: {
				building_name: false,
				name: false,
				detail: false,
				start_date: false,
				start_date2: false,
				start_time: false,
				start_time2: false,
				end_date: false,
				end_date2: false,
				end_time: false,
				end_time2: false,
			},
			edit_params: {
				start_date: "",
				start_time: ""
			}
		}
	},
	created() {
		// 編集なら画面を復元させる
		if (this.$route.query.edit) {
			this.params = {
				name: this.decodeHTML(this.eventData[this.selectedEventIndex].event_name),
				detail: this.decodeHTML(this.eventData[this.selectedEventIndex].detail),
				start_date: this.eventData[this.selectedEventIndex].start_date.split(' ')[0].split('/').join('-'),
				start_time: this.eventData[this.selectedEventIndex].start_date.split(' ')[1],
				end_date: this.eventData[this.selectedEventIndex].end_date.split(' ')[0].split('/').join('-'),
				end_time: this.eventData[this.selectedEventIndex].end_date.split(' ')[1],
				building_name: this.eventData[this.selectedEventIndex].building_name,
				important_flag: this.eventData[this.selectedEventIndex].important_flag,
				event_id: this.eventData[this.selectedEventIndex].id,
			}
			this.edit_params.start_date = this.params.start_date;
			this.edit_params.start_time = this.params.start_time;
		}
	},
	mounted() {
		this.hSize = window.innerHeight;
	},
	methods: {
		async addEvent() {
			await this.$store.dispatch('event/postEventApi', this.params)
			if (this.eventApiStatus) {
				this.$router.push(route_path.event)
			}
		},
		checkValidation() {
			// イベント名の入力チェック
			if (this.params.name === "") {
				this.validation_errors.name = true
			}
			else {
				this.validation_errors.name = false
			}
			// 詳細の入力チェック
			if (this.params.detail === "") {
				this.validation_errors.detail = true
			}
			else {
				this.validation_errors.detail = false
			}
			// 開始日の入力チェック
			if (this.params.start_date === "") {
				this.validation_errors.start_date = true
			}
			// 開始日が現在より未来か
			else if (this.checkStartDate() && !(this.$route.query.edit && this.params.start_date >= this.edit_params.start_date)) {
				this.validation_errors.start_date = false
				this.validation_errors.start_date2 = true
			}
			else {
				this.validation_errors.start_date = false
				this.validation_errors.start_date2 = false
			}
			// 開始時間の入力チェック
			if (this.params.start_time === "") {
				this.validation_errors.start_time = true
			}
			// 開始日時が現在の日時より未来か
			else if (this.checkStartTime() && !(this.$route.query.edit && this.params.start_time >= this.edit_params.start_time)) {
				this.validation_errors.start_time = false
				this.validation_errors.start_time2 = true
			}
			else {
				this.validation_errors.start_time = false
				this.validation_errors.start_time2 = false
			}
			// 終了日の入力チェック
			if (this.params.end_date === "") {
				this.validation_errors.end_date = true
			}
			// 終了日が開始日以降または同日か
			else if (!this.checkEndDate()) {
				this.validation_errors.end_date = false
				this.validation_errors.end_date2 = true
			}
			else {
				this.validation_errors.end_date = false
				this.validation_errors.end_date2 = false
			}
			// 終了時間の入力チェック
			if (this.params.end_time === "") {
				this.validation_errors.end_time = true
			}
			// 終了時間が同日以降の現在時間以降か
			else if (!this.checkEndTime()) {
				this.validation_errors.end_time = false
				this.validation_errors.end_time2 = true
			}
			else {
				this.validation_errors.end_time = false
				this.validation_errors.end_time2 = false
			}
			// ビル名の入力チェック
			if (this.params.building_name === "") {
				this.validation_errors.building_name = true
			}
			else {
				this.validation_errors.building_name = false
			}

			let is_ok = true
			Object.keys(this.validation_errors).filter(key => {
				if (this.validation_errors[key]) {
					is_ok = false
				}
			})
			if (is_ok) {
				this.addEvent()
			}
		},
		// 開始日が昨日以降ではないかチェックする
		checkStartDate() {
			const now = new Date().setHours(0, 0, 0, 0);
			const start_date = new Date(this.params.start_date).setHours(0, 0, 0, 0);
			return now > start_date
		},
		// 開始日が今日で開始時間が過去のものではないかチェック
		checkStartTime() {
			if (!this.checkStartDate()) {
				const now = new Date();
				const timestamp = Date.parse(this.params.start_date.replace('-', '/') + " " + this.params.start_time)
				return now.getTime() > timestamp
			}
		},
		// 終了日が開始日以降または同日かチェック
		checkEndDate() {
			return new Date(this.params.end_date) >= new Date(this.params.start_date)
		},
		// 終了時間が同日の現在時間以降かチェック
		checkEndTime() {
			if (this.checkEndDate()) {
				if (this.params.end_date === this.params.start_date && !this.checkStartTime()) {
					const start_timestamp = Date.parse(this.params.start_date.replace('-', '/') + " " + this.params.start_time)
					const end_timestamp = Date.parse(this.params.end_date.replace('-', '/') + " " + this.params.end_time)
					return start_timestamp < end_timestamp
				}
				else {
					return true
				}
			}
			else {
				return true
			}
		},
		decodeHTML(text) {
			var txt = document.createElement("textarea");
			txt.innerHTML = text;
			return txt.value;
		}
	},
	computed: {
		contentHight() {
			return {
				'--height': this.hSize + 'px'
			}
		},
		rightWrap() {
			return {
				'--height': (this.hSize - 100) + 'px'
			}
		},
		formWrap() {
			return {
				'height': (this.hSize - 310) + 'px'
			}
		},
		...mapState({
			eventApiStatus: state => state.event.apiStatus,
			eventData: state => state.event.eventData,
			selectedEventIndex: state => state.event.selectedEventIndex,
		})
	},
}
</script>

<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/neumorphism.css"></style>
<style scoped src="../assets/css/fp.css"></style>
<style scoped src="../assets/css/event.css"></style>

<style scoped>
.col-sm-9.col-sm-offset-3.col-lg-10.col-lg-offset-2.main {
	width: 88%;
	--height: 0px;
	height: var(--height);
	margin-left: 12%;
}

.right-wrap {
	--height: 0px;
	height: var(--height);
}

.form {
	position: relative;
	display: inline-block;
}
.validation_text {
	position: absolute;
	bottom: -25px;
	color: red;
	font-size: 16px;
	font-weight: bold;
	white-space: pre;
}
</style>