import { getApi, OK } from '../util'
import { build_id } from '../config/const'

const state = {
    apiStatus: null,
    environmentForList: null,
    environmentForGraph: null,
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setEnvironmentForList(state, data) {
        state.environmentForList = data
    },
    setEnvironmentForGraph(state, data) {
        state.environmentForGraph = data
    },
}

const actions = {
    // 環境センサデータ取得(リスト用)
    async getEnvironmentForList(context, data) {
        const params = {
            ...data,
            build_id: build_id,
        }
        context.commit('setApiStatus', null)
        const response = await getApi('get_environment_for_list', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setEnvironmentForList', response.data.data)
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
    // 環境センサデータ取得(グラフ用)
    async getEnvironmentForGraph(context, data) {
        const params = {
            ...data,
            build_id: build_id,
        }
        context.commit('setApiStatus', null)
        const response = await getApi('get_environment_for_graph', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setEnvironmentForGraph', response.data.data)
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}