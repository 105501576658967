<template>
    <div id="sidebar-collapse" class="col-sm-3 col-lg-2 sidebar">
        <div class="profile-sidebar">
            <div class="profile-usertitle">
                <div class="profile-usertitle-name">{{ keyCloak_name }}</div>
                <div class="profile-usertitle-status">
                    <span class="indicator label-success"></span>
                    Online
                </div>
            </div>
            <div class="clear"></div>
        </div>
        <div class="divider"></div>
        <ul class="nav menu">
            <li class="parent">
                <router-link :to="route_path.top" :class="{'active': $route.path === '/'}">
                    <em class="fa fa-home">&nbsp;</em>
                    {{ strings.slider_top }}
                </router-link>
            </li>
            <li class="parent">
                <a v-b-toggle.collapse-1>
                    <em class="fa fa-clipboard">&nbsp;</em>
                    {{ strings.slider_floor }}
                    <span data-toggle="collapse" href="#sub-item-report" class="icon pull-right">
                        <em class="fa fa-plus"></em>
                    </span>
                </a>
                <!-- <ul> -->
                <b-collapse id="collapse-1">
                    <ul class="children">
                        <li>
                            <a @click="toFloorMap('B1F')" :class="{'active': $route.path === '/floor_map' && $route.query.floor === 'B1F'}">
                                <span class="fa fa-arrow-right">&nbsp;</span>
                                フロア(B1F)
                            </a>
                        </li>
                        <li>
                            <a @click="toFloorMap('1F')" :class="{'active': $route.path === '/floor_map' && $route.query.floor === '1F'}">
                                <span class="fa fa-arrow-right">&nbsp;</span>
                                フロア(1F)
                            </a>
                        </li>
                        <li>
                            <a @click="toFloorMap('2F')" :class="{'active': $route.path === '/floor_map' && $route.query.floor === '2F'}">
                                <span class="fa fa-arrow-right">&nbsp;</span>
                                フロア(2F)
                            </a>
                        </li>
                        <li>
                            <a @click="toFloorMap('3F')" :class="{'active': $route.path === '/floor_map' && $route.query.floor === '3F'}">
                                <span class="fa fa-arrow-right">&nbsp;</span>
                                フロア(3F)
                            </a>
                        </li>
                        <li>
                            <a @click="toFloorMap('4F')" :class="{'active': $route.path === '/floor_map' && $route.query.floor === '4F'}">
                                <span class="fa fa-arrow-right">&nbsp;</span>
                                フロア(4F)
                            </a>
                        </li>
                        <li>
                            <a @click="toFloorMap('20F')" :class="{'active': $route.path === '/floor_map' && $route.query.floor === '20F'}">
                                <span class="fa fa-arrow-right">&nbsp;</span>
                                フロア(20F)
                            </a>
                        </li>
                    </ul>
                </b-collapse>
                <!-- </ul> -->
            </li>
            <li class="parent">
                <a v-b-toggle.collapse-2>
                    <em class="fa fa-navicon">&nbsp;</em>
                    {{ strings.slider_sensor }}
                    <span data-toggle="collapse" href="#sub-item" class="icon pull-right">
                        <em class="fa fa-plus"></em>
                    </span>
                </a>
                <b-collapse id="collapse-2">
                    <ul class="children">
                        <!-- <li>
                            <router-link :to="route_path.list" :class="{'active': $route.path === '/list'}">
                                <span class="fa fa-arrow-right">&nbsp;</span>
                                人数カウント
                            </router-link>
                        </li> -->
                        <li>
                            <router-link :to="route_path.floor_user_count_approximate" :class="{'active': $route.path === '/floor_user_count_approximate'}">
                                <span class="fa fa-arrow-right">&nbsp;</span>
                                フロア概算人数
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="route_path.stay" :class="{'active': $route.path === '/stay'}">
                                <span class="fa fa-arrow-right">&nbsp;</span>
                                滞留人数
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="route_path.seat" :class="{'active': $route.path === '/seat'}">
                                <span class="fa fa-arrow-right">&nbsp;</span>
                                着席人数
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="route_path.environment" :class="{'active': $route.path === '/environment'}">
                                <span class="fa fa-arrow-right">&nbsp;</span>
                                環境センサ
                            </router-link>
                        </li>
                        <!-- <li>
                            <router-link :to="route_path.congestion">
                                <span class="fa fa-arrow-right">&nbsp;</span>
                                混雑度ヒートマップ
                            </router-link>
                        </li> -->
                        <li>
                            <!-- TODO: 仮URL -->
                            <a href="https://toilet.mono-connect.jp/tenant/unnagoyanexta/log_in" target="_blank">
                                <span class="fa fa-arrow-right">&nbsp;</span>
                                トイレ利用状況
                            </a>
                        </li>
                        <li>
                            <!-- TODO: 仮URL -->
                            <a href="https://urbannet-nagoya-nexta.exbeacon.com/main/crowded-by-zone/#" target="_blank">
                                <span class="fa fa-arrow-right">&nbsp;</span>
                                スタッフ位置
                            </a>
                        </li>
                    </ul>
                </b-collapse>
            </li>
            <li class="parent">
                <a v-b-toggle.collapse-3>
                    <em class="fa fa-navicon">&nbsp;</em>
                    {{ strings.slider_data }}
                    <span data-toggle="collapse" href="#sub-item" class="icon pull-right">
                        <em class="fa fa-plus"></em>
                    </span>
                </a>
                <b-collapse id="collapse-3">
                    <ul class="children">
                        <template v-for="(floor, floor_name) in floor_ids">
                            <li v-if="floor_name !== 'B1F'" :key="'slider_index_' + floor">
                                <a @click="toReport(floor)" :class="{'active': $route.path === '/report' && $route.query.id === floor}">
                                    <span class="fa fa-arrow-right">&nbsp;</span>
                                    {{ floor_name }}
                                </a>
                            </li>
                        </template>
                    </ul>
                </b-collapse>
            </li>
            <li class="parent">
                <!-- TODO: 仮URL -->
                <a href="https://urbannet-nagoya-nexta.exbeacon.com/main/crowded-by-zone/#" target="_blank">
                    <em class="fa fa-navicon">&nbsp;</em>
                    {{ strings.slider_watch }}
                </a>
            </li>
            <li class="parent">
                <router-link :to="route_path.event" :class="{'active': $route.path === '/event'}">
                    <em class="fa fa-cog">&nbsp;</em>
                    {{ strings.slider_event }}
                </router-link>
            </li>
            <li class="parent">
                <a id="password_edit" href="#" target="_blank">
                    <em class="fa fa-cog">&nbsp;</em>
                    {{ strings.slider_password }}
                </a>
            </li>
            <li class="logout" @click="logout">
                <a href="#">
                    <em class="fa fa-power-off">&nbsp;</em>
                    {{ strings.slider_logout }}
                </a>
            </li>
        </ul>
    </div>
</template>

<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/fp.css"></style>
<style scoped src="../assets/css/neumorphism.css"></style>

<script>
import $ from "jquery";
import {strings} from "../config/strings";
import {route_path} from "../router";
import { keyCloak_name, logout, app_host } from '../util';
import { floor_ids } from '../config/const';
export default {
    data() {
        return {
            strings: strings,
            route_path: route_path,
            floor_ids: floor_ids,
            keyCloak_name: keyCloak_name,
        }
    },
    mounted() {
        $(document).on("click", "ul.nav li.parent > a, .floor-collapse", function () {
            $(this).find("em.fa-plus").toggleClass("fa-minus");
        });
        $(".sidebar span.icon").find("em:first").addClass("fa-plus");
        $("#password_edit").attr("href", app_host + "/cnpf/v1/cnpf/display?open=cnpf_password");
    },
    methods: {
        toReport(floor_id) {
            this.$store.commit('floor/setSelectedFloorId', floor_id)
            this.$router.push(route_path.report + "?id=" + floor_id)
        },
        toFloorMap(floor) {
            this.$router.push(route_path.floor_map + "?floor=" + floor)
        },
        logout() {
            logout()
        }
    },
};
</script>
<style scoped>
.active {
    text-decoration: none;
    background-color: linear-gradient(to bottom right, #a6d4f7, #30a5ff) !important;
    color: #fff;
}
</style>