<template>
    <div>
        <div class="row floor-map-detail">
            <div class="col-xs-6 map-detail">
                <div class="panel panel-default">
                    <div class="panel-body">
                        <div id="map" ref="map" v-if="floorDetailData !== null">
							<div class="ubntfloormap switch_position">
								<!-- <div class="icon_exntn">
									<ul>
										<li><img style="box-shadow: none;" src="../assets/img/sw_shitudo.svg">湿度</li>
									</ul>
									<ul>
										<li><img style="box-shadow: none;" src="../assets/img/sw_ondo.svg">温度</li>
									</ul>
									<ul>
										<li><img style="box-shadow: none;" src="../assets/img/sw_hito.svg">滞留人数</li>
									</ul>
								</div> -->
								<dl>
									<dd>
										<object id="svg" :data="floorDetailData.map_image" type="image/svg+xml" :style="'width:' + width + 'px; heigth:' + height + 'px;'" />
									</dd>
								</dl>
								<div class="bubblebx" v-if="floorDetailData.equipment_list !== undefined && floorDetailData.equipment_list !== null">
									<div class="bubbleinner">
										<div v-for="(area, area_index) in floorDetailData.equipment_list" :key="'bubble_' + area_index" class="bbl" :class="getAreaClass(area.id)">
											<ul>
												<li><img style="box-shadow: none;" src="../assets/img/sw_shitudo.svg"><span>{{ Math.round(area.humidity) }}</span>%</li>
											</ul>
											<ul>
												<li><img style="box-shadow: none;" src="../assets/img/sw_ondo.svg"><span>{{ roundUpTemperature(area.temperature) }}</span>℃</li>
											</ul>
											<ul>
												<li><img style="box-shadow: none;" src="../assets/img/sw_hito.svg"><span>{{ area.human_count }}</span>人</li>
											</ul>
										</div>
									</div>
								</div>
								<!--<div class="switch_button">
									<input id="toggle" class="toggle_input" name="engine" type='checkbox' v-model="is_bubbles" />
									<label for="toggle" class="toggle_label" />
								</div>-->
							</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6">
                <div class="panel panel-default">
                    <div class="panel-heading" style="font-size: 1.0em; height: 40px;line-height: 18px;">
                        アラーム情報
                    </div>
                    <div class="panel-body">
                        <div class="alarm-list">
                            <div>
                                <table>
                                    <thead>
                                        <tr>
											<th colspan="3" style="background: #EBECF0;color: #222;">{{ now_date.year }}-{{ now_date.month }}-{{ now_date.day }}</th>
                                        </tr>
                                        <tr>
                                            <th class="date">時間</th>
                                            <th class="place">場所</th>
                                            <th class="content">内容</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="alert-list-div" :style="alertListHeight">
                                <table v-if="floorDetailData !== null">
                                    <tbody>
										<template v-if="floorDetailData.alarm_data.length === 0">
											<tr>
												<td class="date"></td>
												<td class="place"></td>
												<td class="content">なし</td>
											</tr>
										</template>
										<template v-else>
											<tr v-for="(alert, index) in floorDetailData.alarm_data" :key="'alert_' + index">
												<td class="date">{{ alert.time }}</td>
												<td class="place">{{ alert.area }}</td>
												<td :class="{ content: true, warning: true, error: false }">{{ alert.text }}</td>
											</tr>
										</template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import { mapState } from 'vuex';
import { area_bubbles, floor_ids, roundUpTemperature } from '../config/const';
export default {
	data() {
		return {
			hSize: 0,
			showModal: false,
			width: 0,
			height: 0,
			now_data: null,
			area_bubbles: area_bubbles,
			is_bubbles: false,
		}
	},
	created() {
		this.getNowDate()
		this.getFloorDetail()
	},
	mounted() {
		this.hSize = window.innerHeight;
	},
	methods: {
		async getFloorDetail() {
			const params = {
				floor_id: floor_ids[this.$route.query.floor]
			}
			await this.$store.dispatch('floor/getFloorDetailApi', params)
			if (this.floorApiStatus) {
				this.handleResize()
				window.addEventListener('resize', this.handleResize)

				// 要素を取得
				const element = document.getElementById("svg")
				element.addEventListener('load', () => {
					const area_element = element.contentDocument
					this.floorDetailData.map_data.filter(map_data => {
						if (map_data.area_id_list !== null) {
							// colorを設定する
							map_data.area_id_list.filter((key) => {
								if (area_element.getElementById(key) !== null) {
									area_element.getElementById(key).setAttribute('fill', map_data.color)
								}
							})
						}
					})
				})
			}
		},
		handleResize() {
			const mapWidth = this.$refs.map.clientWidth * 0.8; // 80%にする
			this.width = mapWidth;
			this.height = this.width * 0.751724137931034
		},
		getNowDate() {
			this.date = new Date()
			this.now_date = {
				year: this.date.getFullYear(),
				month: this.date.getUTCMonth() + 1,
				day: this.date.getDate(),
			}
		},
		roundUpTemperature(temperature) {
			return roundUpTemperature(temperature)
		},
		getAreaClass(area_id) {
			let class_name = "bubble_none"
			if (this.area_bubbles[area_id] !== undefined) {
				class_name = this.area_bubbles[area_id]
			}
			return class_name
		},
	},
	computed: {
		...mapState({
			floorApiStatus: state => state.floor.apiStatus,
			floorDetailData: state => state.floor.floorDetailData,
			selectedFloorId: state => state.floor.selectedFloorId,
		}),
		alertListHeight() {
			return {
				'--height': (this.hSize - 305) + 'px'
			}
		},
	},
	watch: {
		'$route' (to, from) {
			if (to !== from) {
				this.getNowDate()
				this.getFloorDetail()
			}
		},
		is_bubbles(val) {
			if (val) {
				$('.bubblebx').show()
			}
			else {
				$('.bubblebx').hide()
			}
		},
	},
	destroyed() {
		window.removeEventListener('resize', this.handleResize)
		this.$store.commit('floor/setFloorDetailData', null)
	}
}
</script>

<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/datepicker3.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/fp.css"></style>
<style scoped src="../assets/css/toastr.css"></style>
<style scoped src="../assets/css/neumorphism.css"></style>
<style scoped src="../assets/css/floor-detail-bubbles.css"></style>

<style scoped>

.alert-list-div {
	--height: 0px;
	max-height: var(--height);
	overflow-y: auto;
}

.floor-map-detail {
	display: flex;
	width: 125%;
	margin-top: 20px;
}

.col-xs-6.map-detail {
	width: 75%;
}
</style>