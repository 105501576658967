import { build_id } from '../config/const'
import { getApi, OK, postApi } from '../util'

const state = {
    apiStatus: null,
    eventData: null,
    selectedEventIndex: 0,
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setEventData(state, data) {
        state.eventData = data
    },
    setSelectedEventIndex(state, data) {
        state.selectedEventIndex = data
    },
}

const actions = {
    // イベント取得処理
    async getEventApi(context) {
        context.commit('setApiStatus', null)
        const response = await getApi('get_events', { build_id: build_id })
        if (response.status === OK) {
            context.commit('setApiStatus', true)
            context.commit('setEventData', response.data.data.event_list);
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
    // イベント追加、編集処理
    async postEventApi(context, data) {
        const params = {
            ...data,
            build_id: build_id,
        }
        context.commit('setApiStatus', null)
        const response = await postApi('add_event', params)
        if (response.status === OK) {
            context.commit('setApiStatus', true)
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
    // イベント削除処理
    async deleteEventApi(context, data) {
        const params = {
            ...data,
            build_id: build_id,
        }
        context.commit('setApiStatus', null)
        const response = await postApi('delete_event', params)
        if (response.status === OK) {
            context.commit('setApiStatus', true)
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}