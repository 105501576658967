import { build_id } from '../config/const'
import { getApi, OK } from '../util'

const state = {
    apiStatus: null,
    alertData: [],
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setAlertData(state, data) {
        state.alertData = data
    },
}

const actions = {
    // アラート取得処理
    async getAlertApi(context) {
        context.commit('setApiStatus', null)
        const response = await getApi('get_alert', {build_id: build_id})
        if (response.status === OK) {
            context.commit('setApiStatus', true)
            context.commit('setAlertData', response.data.data.alert_list)
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}