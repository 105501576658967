<template>
	<div class="list-graph-wrap" id="list-graph-wrap" :style="listGraphWrap">
		<preloader-component color="blue" :scale="0.6" v-if="isShow"/>
		<div class="row" v-if="graphApiStatus">
			<div class="col-xs-12 col-md-12" v-for="(item, index) in graphData.floor_list" :key="'floor_' + index">
				<template v-if="item !== undefined && item !== null">
				<div class="panel panel-default">
					<div class="panel-heading">
						当日の滞留人数 ({{ index }})
					</div>
					<template v-for="(data, i) in item.stayData">
						<div class="panel-body col-xs-4" :key="'graph_' + i" v-if="data.length > 0">
							<div class="graph-title main">{{ i }}</div>
							<div class="env_g">
								<canvas class="main-chart" :id="'line-chart_' + index + '_' + i"></canvas>
							</div>
						</div>
					</template>
				</div>
				</template>
			</div>
		</div>
	</div>
</template>

<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/datepicker3.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/fp.css"></style>
<style scoped src="../assets/css/toastr.css"></style>
<style scoped src="../assets/css/neumorphism.css"></style>

<script>
import { createGraph, graph_labels, GRAPH_TYPE } from '../config/graph'
import { mapState } from 'vuex';
import PreloaderComponent from "./PreloaderComponent.vue"

export default {
	components: {
		PreloaderComponent,
	},
	data() {
		return {
			hSize: 0,
			graph_labels: graph_labels,
			graph_data: [],
			isShow: true,
		}
	},
	created() {
		this.getGraphData()
	},
	mounted() {
		this.hSize = window.innerHeight;
	},
	methods: {
		async getGraphData() {
			await this.$store.dispatch('floor/getStayCountForGraph')
			if (this.graphApiStatus) {
				this.isShow = false //ロード中のプログレスを隠す
				Object.keys(this.graphData.floor_list).forEach(key => {
					Object.keys(this.graphData.floor_list[key].stayData).forEach(item => {
						if(this.graphData.floor_list[key].stayData[item].length > 0) {
							this.setGraphs(this.graphData.floor_list[key].stayData[item], key + '_' + item, this.graphData.time, this.graphData.floor_list[key].stayMaxData[item])
						}
					})
				})
			}
		},
		setGraphs(data, index, time, max) {
			let graph_datas = [[]]
			graph_datas[0] = data
			createGraph(GRAPH_TYPE.count, document.getElementById("line-chart_" + index), graph_datas, time, ["滞留人数"], max)
		},
	},
	computed: {
		...mapState({
			graphApiStatus: state => state.floor.apiStatus,
			graphData: state => state.floor.stayCountForGraph,
		}),
		listGraphWrap() {
			return {
				'--height': (this.hSize - 150) + 'px'
			}
		}
	},
}
</script>

<style scoped>
.list-graph-wrap {
	--height: 100%;
	height: var(--height);
	overflow-y: scroll;
}

.panel {
	box-shadow: none;
}
</style>