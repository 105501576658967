<template>
    <div class="col-xs-12 col-md-12" style="padding-left: 1px; padding-right: 1px;" :style="contentHight">
        <preloader-component color="blue" :scale="0.6" v-if="isShow"/>
        <div class="panel panel-default" v-if="seatCountApiStatus">  
            <div class="panel-body">
                <div class="floor-list" style="overflow-y: scroll; padding-bottom: 15px; padding-right: 15px" :style="floorListHeight">
                    <template v-for="(floorData, index) in seatCountData.floor_list">
                        <div :key="'table_header_' + index">
                            <h2>当日の着席人数</h2>
                            <h2>{{index}}</h2>
                            <table :style="{'table-layout': 'fixed'}">
                                <thead>
                                    <tr>
                                        <th colspan="1">時間</th>
                                        <template v-for="(data, index) in floorData[0].area_data">
                                            <th v-if="data !== null" class="main" :key="'head_' + index">{{data.area_name}}</th>
                                        </template>
                                        <th class="main">合計</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(data, index) in floorData">
                                        <tr :key="'tr_' + index" v-if="Object.keys(data.area_data).length !== 0">
                                            <td v-if="data !== null" class="main">{{data.time}}</td>
                                            <template v-for="(data, index) in data.area_data">
                                                <td v-if="data !== null" class="main" :key="'td' + index">{{data.count}}</td>
                                            </template>
                                            <td class="main">{{ getTotal(data.area_data)}}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                     </template>
                </div> 
            </div>
        </div>
    </div>
</template>

<script scoped>
import { mapState } from "vuex";
import PreloaderComponent from "./PreloaderComponent.vue"
import {floor_ids} from "../config/const";

export default {
  components: {
		PreloaderComponent,
	},
  data() {
    return {
      hSize: 0,
      isShow: true,
    };
  },
  created() {
    this.getFloorData();
  },
  mounted() {
    this.hSize = window.innerHeight;
  },
  methods: {
    async getFloorData() {
      const params = {
				floor_id_list: [
					floor_ids['2F'], 
					floor_ids['20F']
				],
        area_id_list: [
					floor_ids['2F'] + '005', //タッチダウンスペース西
          floor_ids['2F'] + '006', //タッチダウンスペース東
					floor_ids['20F'] + '003' //スカイテラス
				]
			}
      await this.$store.dispatch("floor/getSeatCountForList", params);
      if (this.seatCountApiStatus) {
        this.isShow = false //ロード中のプログレスを隠す
      }
    },
    getTotal(data){
        let total = 0;
        data.filter((item) => {
          if (item.count !== null) {
              let sep = /\//;
              let arrayString = item.count.split(sep);
              total = total + parseInt(arrayString[0])
          }
        })
        return total
    }
  },
  computed: {
    contentHight() {
      return {
        "--height": this.hSize - 120 + "px",
      };
    },
    floorListHeight() {
      return {
        height: this.hSize - 180 + "px",
      };
    },
    ...mapState({
      seatCountApiStatus: (state) => state.floor.apiStatus,
      seatCountData: (state) => state.floor.seatCountForList,
    }),
  },
};
</script>

<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/fp.css"></style>
<style scoped src="../assets/css/neumorphism.css"></style>

<style scoped>
.panel {
  box-shadow: none;
}
::-webkit-scrollbar {
  height: 7px;
}
</style>
