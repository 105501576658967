import { setKeyCloak } from './util';
import { mapState } from 'vuex';
import Vue from 'vue'
import App from './App.vue'
import Router from './router'
import Toastr from 'vue-toastr';
import BootstrapVue from 'bootstrap-vue'
import VTooltip from 'v-tooltip'
import jQuery from 'jquery'
import VueHead from 'vue-head'
import store from './store'
import VueFullscreen from "vue-fullscreen";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js"

global.jquery = jQuery
global.$ = jQuery
window.$ = window.jQuery = require('jquery')

Vue.config.productionTip = false

Vue.use(VueHead)
Vue.use(Toastr)
Vue.use(BootstrapVue)
Vue.use(VTooltip)
Vue.use(VueFullscreen)
Vue.use(VueKeyCloak, {
  config: {
    realm: 'cnpf',
    url: process.env.VUE_APP_LOGIN_URL,
    clientId: 'bmv_client',
    secret_client: '2db531e3-cad3-4893-85bc-65a15348fcab'
  },
  init: {
    onLoad: 'login-required',
    checkLoginIframe: false
  },
  logout: {
    redirectUri: process.env.VUE_APP_LOGIN_URL
  },
  onReady: keyCloak => {
    new Vue({
      store,
      router: Router,
      render: h => h(App),
      data() {
        return {
          is_master: false,
          is_login: false,
        }
      },
      created() {
        setKeyCloak(keyCloak).then((res) => {
          this.loginApis(res)
        })
      },
      mounted() {
        this.$toastr.defaultTimeout = 30000;
      },
      methods: {
        async loginApis(res) {
          // ログインチェックAPI
          this.$store.dispatch('userCheck/userCheckApi', {uuid: res})
          .then(() => {
            this.is_login = true
            if (this.is_master && this.is_login) {
              this.$mount('#app')
            }
          })
          // マスタ取得
          this.$store.dispatch('master/getMstApi')
          .then(() => {
            this.is_master = true
            if (this.is_master && this.is_login) {
              this.$mount('#app')
            }
          })
        }
      },
      computed: {
        ...mapState({
          userCheckApiStatus: state => state.userCheck.apiStatus,
          errorCode: state => state.error.errorCode,
          masterApiStatus: state => state.master.apiStatus,
        })
      }
    })
  },
})