<template>
    <div class="col-xs-12 col-md-12" style="padding-left: 1px; padding-right: 1px;" :style="contentHight" v-if="userCountApiStatus">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="floor-list" style="overflow-y: scroll; padding-bottom: 15px; padding-right: 15px" :style="floorListHeight">
                    <h2>最新の概算人数</h2>
                    <table :style="{'table-layout': 'fixed'}">
                        <thead>
                            <tr>
                                <th colspan="1">時間</th>
                                <template v-for="(data, index) in userCountData.floor_names">
                                    <th v-if="data !== null" class="main" :key="'table_header_' + index">{{data}}</th>
                                </template>
                                <th class="main">合計</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(data, index) in userCountData.floor_list">
                                <tr :key="'tr_' + index">
                                    <td v-if="data !== null" class="main">{{index}}</td>
                                    <template v-for="(data, index) in data">
                                        <td v-if="data !== null" class="main" :key="'td' + index">{{data.floor_data.count_approximate}}</td>
                                    </template>
                                    <td class="main" >{{ getTotal(data)}}</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script scoped>
import { mapState } from 'vuex';
import {floor_ids} from "../config/const";

export default {
	data() {
		return {
            hSize: 0,
		}
	},
    created() {
        this.getFloorData()
    },
    mounted() {
        this.hSize = window.innerHeight;
    },
    methods: {
        async getFloorData() {
            const params = {
				floor_id_list: [
					floor_ids['B1F'], 
					floor_ids['1F'],
                    floor_ids['2F'], 
					floor_ids['3F'],
                    floor_ids['4F'], 
					floor_ids['20F']
				],
			}
            await this.$store.dispatch('floor/getUserCountApproximateByTimeForList', params)
            if (this.userCountApiStatus) {
                //TODO 
            }
        },
        getTotal(data){
            let total = 0;
            data.filter((item) => {
                if (item.floor_data.count_approximate !== null) {
                    total = total + parseInt(item.floor_data.count_approximate)
                }
            })
            return total
        }
    },
    computed: {
		contentHight() {
			return {
				'--height': (this.hSize - 120) + 'px'
			}
		},
		floorListHeight() {
			return {
				'height': (this.hSize - 180) + 'px'
			}
		},
        ...mapState({
            userCountApiStatus: state => state.floor.apiStatus,
            userCountData: state => state.floor.floorDataByTime,
        })
	},
}
</script>

<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/fp.css"></style>
<style scoped src="../assets/css/neumorphism.css"></style>

<style scoped>
.panel {
	box-shadow: none;
}
::-webkit-scrollbar {
    height: 7px;
}
</style>
