<template>
	<div class="col-xs-6 col-md-4" @click="toFloorMap()">
		<div :class="[{blinking_warn: is_warning, blinking_err: is_err},'panel panel-default']">
			<div class="panel-heading">
				{{ floor }}
				<div class="env_info">
					<div class="list_env_ttl">温度</div>
					<div class="list_env_val"><i :class="['circle', setTemperatureCircleClass(temperature)]"></i>{{ roundUpTemperature(temperature) }}℃</div>
					<div class="list_env_ttl">湿度</div>
					<div class="list_env_val">{{ humidity }}％</div>
				</div>
			</div>
			<div class="panel-body">
				<div id="demo" ref="demo">
                    <object :id="'svg_' + floor" :data="map_data" type="image/svg+xml" :style="'width:' + width + 'px; heigth:' + height + 'px;'" />
				</div>
			</div>
		</div>
	</div>
</template>

<script scoped>
import { mapState } from 'vuex';
import {setTemperatureCircleClass, roundUpTemperature} from "../config/const";
import {route_path} from "../router";

export default {
    props: {
        floor: String,
        temperature: Number,
        humidity: Number,
        index: String,
        floor_id: String,
        map_data: String,
        error_level: Number,
        map_svg_data: Array,
    },
	data() {
        return {
            is_warning: false,
            is_err: false,
			width: 0,
			height: 0,
            is_map_move: false,
            mouse_pointer: {
                x: 0,
                y: 0,
            }
		}
	},
    created() {
        this.is_warning = this.error_level === 1
        this.is_err = this.error_level === 2
    },
	mounted() {
        this.handleResize()
        window.addEventListener('resize', this.handleResize)

        // マップをドラッグ操作中に画面遷移しないように
        window.addEventListener('mousedown', this.onMouseDownMap)
        window.addEventListener('mouseup', this.onMouseUpMap)

        // マップsvgを取得
        this.$nextTick(() => {
            const element = document.getElementById("svg_" + this.floor)
            element.addEventListener('load', () => {
                const area_element = element.contentDocument
                this.map_svg_data.filter(map_data => {
                    if (map_data.area_id_list !== null) {
                        // colorを設定する
                        map_data.area_id_list.filter((key) => {
                            if (area_element.getElementById(key) !== null) {
                                area_element.getElementById(key).setAttribute('fill', map_data.color)
                            }
                        })
                    }
                })
            })
        })
    },
	methods: {
		toFloorMap() {
            if (this.is_map_move) {
                this.$router.push(route_path.floor_map + "?floor=" + this.floor)
            }
		},
        handleResize() {
            this.width = this.$refs.demo.clientWidth;
            this.height = this.$refs.demo.clientWidth * 0.751724137931034;
        },
        setTemperatureCircleClass(temperature) {
            return setTemperatureCircleClass(temperature, this.mstTemperatureLabel)
        },
        roundUpTemperature(temperature) {
            return roundUpTemperature(temperature)
        },
        onMouseDownMap(e) {
            this.mouse_pointer.x = e.screenX
            this.mouse_pointer.y = e.screenY
            this.is_map_move = false
        },
        onMouseUpMap(e) {
            if (this.mouse_pointer.x === e.screenX && this.mouse_pointer.y === e.screenY) {
                this.is_map_move = true
            }
        },
	},
    computed: {
        ...mapState({
            mstTemperatureLabel: state => state.master.mstTemperatureLabel,
        })
	},
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
        window.removeEventListener('mousedown', this.onMouseDownMap)
        window.removeEventListener('mouseup', this.onMouseUpMap)
    },
}
</script>

<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/fp.css"></style>
<style scoped src="../assets/css/neumorphism.css"></style>