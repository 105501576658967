<template>
    <div class="col-xs-12 col-md-12" style="padding-left: 1px; padding-right: 1px;" :style="contentHight">
        <preloader-component color="blue" :scale="0.6" v-if="isShow"/>
        <div class="panel panel-default" v-if="environmentApiStatus">  
            <div class="panel-body">
                <div class="floor-list" style="overflow-y: scroll; padding-bottom: 15px; padding-right: 15px" :style="floorListHeight">
                    <template v-for="(floorData, index) in environmentData.floor_list">
                        <div :key="'table_header_' + index">
                            <h2>環境センサー {{index}}</h2>
                            <table :style="{'table-layout': 'fixed'}">
                                <thead>
                                    <tr>
                                        <th colspan="1">取得時刻</th>
                                        <th class="main">温度(℃)</th>
                                        <th class="main">湿度(%)</th>
                                        <th class="main">照度(lx)</th>
                                        <th class="main">騒音(db)</th>
                                        <th class="main">気圧(hPa)</th>
                                        <th class="main">総揮発性有機化学物量(ppb)</th>
                                        <th class="main">二酸化炭素濃度(ppm)</th>
                                        <th class="main">不快指数</th>
                                        <th class="main">熱中症危険度</th>
                                        <th class="main">地震センシング値</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(data, i) in floorData">
                                        <tr :key="'tr_' + i">
                                            <td v-if="data.time !== null" class="main">{{data.time}}</td>
                                            <td v-if="data.floor_data.temperature !== null" class="main">{{data.floor_data.temperature}}</td>
                                            <td v-if="data.floor_data.humidity !== null" class="main">{{data.floor_data.humidity}}</td>
                                            <td v-if="data.floor_data.ambientlight !== null" class="main">{{data.floor_data.ambientlight}}</td>
                                            <td v-if="data.floor_data.soundnoise !== null" class="main">{{data.floor_data.soundnoise}}</td>
                                            <td v-if="data.floor_data.pressure !== null" class="main">{{data.floor_data.pressure}}</td>
                                            <td v-if="data.floor_data.etvoc !== null" class="main">{{data.floor_data.etvoc}}</td>
                                            <!-- <td v-if="data.floor_data.eco2 !== null" class="main">{{data.floor_data.eco2}}</td> -->
                                            <td v-if="data.floor_data.eco2 !== null" class="main">-</td>
                                            <td v-if="data.floor_data.discomfort !== null" class="main">{{data.floor_data.discomfort}}</td>
                                            <td v-if="data.floor_data.heatstroke !== null" class="main">{{data.floor_data.heatstroke}}</td>
                                            <td v-if="data.floor_data.seismic !== null" class="main">{{data.floor_data.seismic}}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                     </template>
                </div> 
            </div>
        </div>
    </div>
</template>

<script scoped>
import { mapState } from 'vuex';
import PreloaderComponent from "./PreloaderComponent.vue"

export default {
    components: {
		PreloaderComponent,
	},
	data() {
		return {
            hSize: 0,
            isShow: true,
		}
	},
    created() {
        this.getFloorData()
    },
    mounted() {
        this.hSize = window.innerHeight;
    },
    methods: {
        async getFloorData() {

            await this.$store.dispatch('environment/getEnvironmentForList')
            if (this.environmentApiStatus) {
                this.isShow = false //ロード中のプログレスを隠す
            }
        },
    },
    computed: {
		contentHight() {
			return {
				'--height': (this.hSize - 120) + 'px'
			}
		},
		floorListHeight() {
			return {
				'height': (this.hSize - 180) + 'px'
			}
		},
        ...mapState({
            environmentApiStatus: state => state.environment.apiStatus,
            environmentData: state => state.environment.environmentForList,
        })
	},
}
</script>

<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/fp.css"></style>
<style scoped src="../assets/css/neumorphism.css"></style>

<style scoped>
.panel {
	box-shadow: none;
}
::-webkit-scrollbar {
    height: 7px;
}
</style>
