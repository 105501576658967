<template>
	<div>
		<div class="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2 main" :style="contentHight">
			<div class="right-wrap" :style="rightWrap">
				<div class="tabs">
					<ul role="tablist" class="nav nav-tabs">
						<li role="presentation" class="nav-item">
							<a :class="['nav-link', {active: activeTabIndex==0}]" @click="tabClick(0)">リスト</a>
						</li>
						<li role="presentation" class="nav-item">
							<a :class="['nav-link', {active: activeTabIndex==1}]" @click="tabClick(1)">グラフ</a>
						</li>
					</ul>
				</div>

				<div v-if="activeTabIndex === 0">
					<SeatTable />
				</div>
				<div v-else-if="activeTabIndex == 1">
					<SeatGraph />
				</div>
			</div>
		</div>
	</div>
</template>

<script scoped>

import SeatTable from "./SeatTable.vue"
import SeatGraph from "./SeatGraph.vue"

export default {
	components: {
		SeatTable,
		SeatGraph
	},
	head: {
		title: function() { return { inner:  'Lumino - 着席人数' } },
	},
	data() {
		return {
			hSize: 0,
			activeTabIndex: 0
		}
	},
	mounted() {
		this.hSize = window.innerHeight;
	},
	computed: {
		contentHight() {
			return {
				'--height': (this.hSize - 60) + 'px'
			}
		},
		rightWrap() {
			return {
				'--height': (this.hSize - 100) + 'px'
			}
		}
	},
	methods: {
		tabClick(index) {
			this.activeTabIndex = index
		}
	}
}
</script>


<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/tab_style.css"></style>

<style scoped>

.col-sm-9.col-sm-offset-3.col-lg-10.col-lg-offset-2.main {
	width: 88%;
	--height: 0px;
	height: var(--height);
	margin-left: 12%;
}

.right-wrap {
	--height: 0px;
	height: var(--height);
	border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    background: linear-gradient(to bottom right, #f0f2f5, #e9ecf2) !important;
    box-shadow: 5px 5px 5px #696969, -8px -8px 7px #ffffff;
    margin-top: 15px;
    margin-left: 20px;
}

.nav-item {
    width: 50%;
    text-align: center;
}

</style>
