<template>
    <div class="error-main">
        <div v-if="code === 502">
            <p>
                {{ code }}
            </p>
            <p>
                エラーが発生しました。
            </p>
        </div>
        <div v-if="code !== error_codes.AUTH_ERROR && code !== 502">
            <p>
                {{ code }}
            </p>
            <p>
                エラーが発生しました。
            </p>
            <button @click="event">
                トップに戻る
            </button>
        </div>
        <div v-if="code === error_codes.AUTH_ERROR">
            <p>
                認証エラー
            </p>
            <p>
                アクセス権限がありません。
            </p>
            <button @click="event">
                ログアウト
            </button>
        </div>
    </div>
</template>

<script>
import { route_path } from '../router'
import { logout } from '../util'
import { error_codes } from '../store/error'
export default {
    data() {
        return {
            error_codes: error_codes,
        }
    },
    props: {
        code: Number,
        message: String,
    },
    methods: {
        event() {
            // 認証エラー
            if (this.code === error_codes.AUTH_ERROR) {
                logout()
            }
            // その他エラー
            else {
                this.$store.commit('error/setApiStatus', false)
                this.$router.push(route_path.top)
            }
        }
    }
}
</script>

<style scoped>
.error-main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 1100;
}
.error-main div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.error-main div p {
    text-align: center;
    font-size: 30px;
}
.error-main div p:first-child {
    font-size: 40px;
    font-weight: bold;
}
.error-main div button {
    width: 100%;
    text-align: center;
    font-size: 30px;
}
</style>