<template>
	<div>
		<div class="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2 main" :style="contentHight">
			<div class="right-wrap" :style="rightWrap">
				<div class="row">
					<ol class="breadcrumb">
						<li><a href="#"><em class="fa fa-home"></em></a></li>
						<li class="active">{{ title }}</li>
					</ol>
				</div>
				<hr style="margin:unset; margin-bottom: 10px; border: 0;border-top: 1px solid #cdd2db;">

				<div style="height: 100%; overflow-y: scroll;padding-top: 2px;" v-if="floorData !== null">
					<div v-for="(row, row_index) in sliceByNumber(floorData, 3)" :key="'row_' + row_index" class="row">
						<div v-for="(item, index) in row" :key="'floor_' + index">
							<floor-panel-component
								:floor="item.floor_data.floor"
								:temperature="Number(item.floor_data.temperature)"
								:humidity="Number(item.floor_data.humidity)"
								:index="row_index + '_' + index"
								:floor_id="item.floor_data.id"
								:map_data="item.floor_data.map_image"
								:error_level="item.floor_data.error_level"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script scoped>
import FloorPanelComponent from "./FloorPanelComponent.vue"
import { floor_ids, sliceByNumber } from '../config/const'
import { mapState } from 'vuex';

export default {
	components: {
		FloorPanelComponent
	},
	head: {
		title: function() {
			return {
				inner:  'Lumino - フロア'
			}
		}
	},
	data() {
		return {
			hSize: 0,
			handler: null,
			width: 0,
			height: 0,
			id: this.$route.query.id,
			title: "",
		}
	},
    created() {
		this.setFloorCount()
    },
	mounted() {
		this.hSize = window.innerHeight;
	},
	methods: {
		async getFloorData(floor_id) {
			await this.$store.dispatch('floor/getFloorApi', floor_id)
		},
		setFloorCount() {
			switch (this.$route.query.id) {
				case '0':
					this.title = "フロア 20F"
					this.getFloorData({
						floor_id_list: [
							floor_ids['20F']
						]
					})
					break
				case '1':
					this.title = "フロア B1～4F"
					this.getFloorData({
						floor_id_list: [
							floor_ids["B1"],
							floor_ids['1F'],
							floor_ids['2F'],
							floor_ids['3F'],
							floor_ids['4F'],
						]
					})
					break
			}
		},
		sliceByNumber(array, number) {
			return sliceByNumber(array, number)
		},
	},
	computed: {
		...mapState({
			floorApiStatus: state => state.floor.apiStatus,
			floorData: state => state.floor.floorData,
		}),
		contentHight() {
			return {
				'--height': this.hSize + 'px'
			}
		},
		rightWrap() {
			return {
				'--height': (this.hSize - 100) + 'px'
			}
		}
	},
	watch: {
		'$route' () {
			this.setFloorCount()
		}
	}
}
</script>

<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/datepicker3.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/fp.css"></style>
<style scoped src="../assets/css/toastr.css"></style>
<style scoped src="../assets/css/neumorphism.css"></style>

<style scoped>
.col-sm-9.col-sm-offset-3.col-lg-10.col-lg-offset-2.main {
	width: 88%;
	--height: 0px;
	height: var(--height);
	margin-left: 12%;
}

.right-wrap {
	--height: 0px;
	height: var(--height);
}

.row:last-child {
	margin-bottom: 50px;
}
</style>