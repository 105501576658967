<template>
	<div class="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2 main">
		<div class="right-wrap">
			<div class="row">
				<ol class="breadcrumb">
					<li><a href="#">
							<em class="fa fa-home"></em>
						</a></li>
					<li class=""><em class=" fa fa-clipboard"></em>レポート</li>
					<li class="active">{{ floor }}</li>
				</ol>
			</div>
			<hr style="margin:unset; margin-bottom: 10px; border: 0;border-top: 1px solid #cdd2db;">

			<div style="height: 100%;" id="list-graph-wrap">
				<div class="row">
					<div class="col-xs-12 col-md-12">
						<div class="panel panel-default">
							<div class="panel-heading" v-bind:class="[isActive ? '' : 'wrapper']">
								<div class="report-title">{{ floor }}</div>
								<div class="switch-menu">
									<article>
										<div class="menu">
											<datepicker :language="language" format="yyyy/MM/dd" @closed="pickerClosed(true)" v-model="start_date"></datepicker>
										</div>
									</article>
									<article>
										<div class="menu">
											<datepicker :language="language" format="yyyy/MM/dd" @closed="pickerClosed(false)" v-model="end_date"></datepicker>
										</div>
									</article>
									<!-- <article v-for="(check, checked_index) in filter_checked" :key="'check_' + checked_index" class="mycheckbox">
										<input type="checkbox" :id="checked_index" v-model="filter_checked[checked_index]" @change="changed()">
										<label :for="checked_index">
											{{ report_filter_labels[checked_index] }}
										</label>
									</article> -->
								</div>
								<!-- TODO: テーブル、グラフの表示項目切り替え処理追加する -->
								<div class="list-menu" style="width: 10%;">
									<a v-tooltip.top="'表示設定'" href="#" data-placement="bottom" @click="toggleMenu()">
										<em class="fa fa-tasks"></em>
									</a>
									<div id="report-modal-menu" :style="{display: is_menu ? 'block' : 'none'}">
										<div class="report-modal-header">表示設定</div>
										<div class="report-modal-content" style="height: 500px; overflow-y: auto;">
											<div class="modal-content-title">項目</div>
											<ul class="mycheckbox">
												<li><input type="checkbox" id="chk_floor" v-model="area_shows[0]"><label for="chk_floor">フロア</label></li>
												<template v-if="table_data_list[0] !== undefined">
													<li v-for="(area, area_index) in table_data_list[0].data[0].area_data" :key="'area_' + area_index">
														<input type="checkbox" :id="'chk_area_' + area_index" v-model="area_shows[area_index + 1]">
														<label :for="'chk_area_' + area_index">
															{{ area.name }}
														</label>
													</li>
												</template>
											</ul>
											<div class="modal-content-title">グラフ</div>
											<ul class="mycheckbox">
												<li><input type="checkbox" id="chk_graph_1" v-model="graph_shows[0]"><label for="chk_graph_1">フロア</label></li>
												<li><input type="checkbox" id="chk_graph_2" v-model="graph_shows[1]"><label for="chk_graph_2">照度</label></li>
												<li><input type="checkbox" id="chk_graph_3" v-model="graph_shows[2]"><label for="chk_graph_3">騒音</label></li>
												<li><input type="checkbox" id="chk_graph_4" v-model="graph_shows[3]"><label for="chk_graph_4">CO2濃度</label></li>
												<li><input type="checkbox" id="chk_graph_5" v-model="graph_shows[4]"><label for="chk_graph_5">総揮発性有機化学物量相当値</label></li>
												<li><input type="checkbox" id="chk_graph_6" v-model="graph_shows[5]"><label for="chk_graph_6">熱中症危険度指数</label></li>
												<li><input type="checkbox" id="chk_graph_7" v-model="graph_shows[6]"><label for="chk_graph_7">入館人数</label></li>
												<li><input type="checkbox" id="chk_graph_8" v-model="graph_shows[7]"><label for="chk_graph_8">退館人数</label></li>
											</ul>
										</div>
										<div class="report-modal-footer">
											<div class="button-wrapper">
												<button type="button" class="btn btn-default" id="cancel" @click="cancel()">キャンセル</button>
												<!-- <button type="button" class="btn btn-success" id="success" @click="save()">反映する</button> -->
											</div>
										</div>
									</div>
									<a href="#" v-tooltip.top="'CSVダウンロード'" data-placement="bottom" @click="getCsv()">
										<em class="fa fa-download"></em>
									</a>
								</div>
							</div>
							<div class="report-contents">
								<preloader-component color="blue" :scale="0.6" v-if="isShow"/>
								<h2 style="text-align: center;" v-if="isNodata">該当データはありません。</h2>
								<div class="report-item" v-for="(table, table_index) in table_data_list" :key="'table_' + table_index">
									<div class="report-list col-xs-8">
										<div class="panel-body col-xs-12">
											<div class="floor-list">
												<div>
													<table :style="{'table-layout': 'fixed', 'width': table_width + 'px'}">
														<thead>
															<tr>
																<th colspan="3"></th>
																<th v-if="area_shows[0]" :colspan="setColSpan(true, table.data[0].floor_data)" class="main">フロア</th>
																<template v-for="(area, area_index) in table.data[0].area_data">
																	<th v-if="area_shows[area_index + 1]" :colspan="setColSpan(false, area)" :class="'room' + (area_index + 1)" :key="'room' + area_index + '-head'">{{ area.name }}</th>
																</template>
															</tr>
															<tr>
																<th colspan="1">日付</th>
																<th colspan="1">時間</th>
																<th colspan="1">天気</th>
																<template v-if="area_shows[0]">
																	<th v-if="table.data[0].floor_data.temperature !== null" class="main">温度</th>
																	<th v-if="table.data[0].floor_data.humidity !== null" class="main">湿度</th>
																	<th v-if="table.data[0].floor_data.count !== null" class="main">滞留人数</th>
																	<th v-if="table.data[0].floor_data.illuminance !== null" class="main">照度</th>
																	<th v-if="table.data[0].floor_data.noise !== null" class="main">騒音</th>
																	<th v-if="table.data[0].floor_data.co2_concentration !== null" class="main">CO2濃度</th>
																	<th v-if="table.data[0].floor_data.equivalent !== null" class="main">総揮発性有機化学物量相当値</th>
																	<th v-if="table.data[0].floor_data.heat_stroke !== null" class="main">熱中症危険度指数</th>
																	<th v-if="table.data[0].floor_data.in_count !== null" class="main">入館人数</th>
																	<th v-if="table.data[0].floor_data.out_count !== null" class="main">退館人数</th>
																</template>
																<template v-for="(area, area_index) in table.data[0].area_data">
																	<template v-if="area_shows[area_index + 1]">
																		<th v-if="area.temperature !== null" :class="'room' + (area_index + 1)" :key="'room1' + area_index">温度</th>
																		<th v-if="area.humidity !== null" :class="'room' + (area_index + 1)" :key="'room2' + area_index">湿度</th>
																		<th v-if="area.count !== null" :class="'room' + (area_index + 1)" :key="'room3' + area_index">使用座席数</th>
																		<th v-if="area.human_count !== null" :class="'room' + (area_index + 1)" :key="'room4' + area_index">滞留人数</th>
																	</template>
																</template>
															</tr>
														</thead>
													</table>
												</div>
												<div>
													<table :style="{'table-layout': 'fixed', 'width': table_width + 'px'}">
														<tbody>
															<tr v-for="(data, index) in table.data" :key="'datas_' + index">
																<td rowspan="24" v-if="index === 0">{{ table.date }}</td>
																<td class="">{{ data.time }}</td>
																<td class="data-main"><img :src="data.weather_icon"></td>
																<template v-if="area_shows[0]">
																	<td class="data-main" v-if="data.floor_data.temperature !== null"><i :class="['circle', setTemperatureCircleClass(data.floor_data.temperature)]"></i>{{ roundUpTemperature(data.floor_data.temperature) }}℃</td>
																	<td class="data-main" v-if="data.floor_data.humidity !== null">{{ data.floor_data.humidity }}%</td>
																	<td class="data-main" v-if="data.floor_data.count !== null">{{ data.floor_data.count }}人</td>
																	<td class="data-main" v-if="data.floor_data.illuminance !== null">{{ data.floor_data.illuminance }}lx</td>
																	<td class="data-main" v-if="data.floor_data.noise !== null">{{ data.floor_data.noise }}db</td>
																	<td class="data-main" v-if="data.floor_data.co2_concentration !== null">{{ data.floor_data.co2_concentration }}ppm</td>
																	<td class="data-main" v-if="data.floor_data.equivalent !== null">{{ data.floor_data.equivalent }}ppb</td>
																	<td class="data-main" v-if="data.floor_data.heat_stroke !== null">{{ data.floor_data.heat_stroke }}degC</td>
																	<td class="data-main" v-if="data.floor_data.in_count !== null">{{ data.floor_data.in_count }}人</td>
																	<td class="data-main" v-if="data.floor_data.out_count !== null">{{ data.floor_data.out_count }}人</td>
																</template>
																<template v-for="(area, area_index) in data.area_data">
																	<template v-if="area_shows[area_index + 1]">
																		<td class="data-main" :key="'area' + area_index + '_temperature'" v-if="area.temperature !== null"><i :class="['circle', setTemperatureCircleClass(area.temperature)]"></i>{{ roundUpTemperature(area.temperature) }}℃</td>
																		<td class="data-main" :key="'area' + area_index + '_humidity'" v-if="area.humidity !== null">{{ area.humidity }}%</td>
																		<td class="data-main" :key="'area' + area_index + '_count'" v-if="area.count !== null">{{ area.count }}</td>
																		<td class="data-main" :key="'area' + area_index + '_human_count'" v-if="area.human_count !== null">{{ area.human_count }}人</td>
																	</template>
																</template>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
									<report-graph :index="table_index" :date="table.date" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="deltmodal">
			<div class="modal__bg js-modal-close"></div>
			<div class="deltmodalcon">
				<div class="dlmdlti">
					<!-- <dl>
						<dt>{{ selected_event_data !== null ? selected_event_data.event_name : "" }}</dt>
						<dd>{{ selected_event_data !== null ? selected_event_data.start_date : "" }}<span>-</span>{{ selected_event_data !== null ? selected_event_data.end_date : "" }}</dd>
					</dl> -->
					<ul>
						<li>期間は1週間以内で指定してください。</li>
					</ul>
				</div>
				<div class="dlmdlbtn">
					<!-- <p>期間は1週間以内で指定してください。</p> -->
					<dl>
						<dt>
							<button class="cnclbtn" @click="togglePeriodModal(false)">
								<span class="btn">閉じる</span>
							</button>
						</dt>
					</dl>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { floor_ids, report_filter_labels, setTemperatureCircleClass, roundUpTemperature } from '../config/const';
import { mapState } from 'vuex';
import ReportGraph from './ReportGraph.vue'
import Datepicker from 'vuejs-datepicker'
import { ja } from "vuejs-datepicker/dist/locale";
import moment from 'moment';
import PreloaderComponent from "./PreloaderComponent.vue"
import $ from 'jquery'

export default {
	head: {
		title: function() { return { inner:  'Lumino - フロア一覧' } },
	},
	components: {
		ReportGraph,
		Datepicker,
		PreloaderComponent,
	},
	data() {
		return {
			floor: "",
			show: [true, true, true],
			graphView: [true, true, true],
			is_menu: false,
			active_button: [true, false, false, false, false],
			table_data_list: [],
			table_width: 0,
			graph_data: [],
			start_date: "",
			end_date: "",
			filter_checked: [
				false, // 日曜日
				false, // 月曜日
				false, // 火曜日
				false, // 水曜日
				false, // 木曜日
				false, // 金曜日
				false, // 土曜日
				false, // 祝日
			],
			report_filter_labels: report_filter_labels,
			current_area_shows: [true],
			area_shows: [true],
			graph_shows: [true, true, true, true, true, true, true, true,],
			isShow: true,
			isActive: true,
			isNodata: false,
			prev_start_date: "",
			prev_end_date: ""
		}
	},
	created() {
		this.floor = Object.keys(floor_ids)[Object.values(floor_ids).indexOf(this.$route.query.id)]
		this.getNowDate()
	},
	methods: {
		async getFloorHistoryData() {
			this.isActive = false
			this.isShow = true
			this.isNodata = false
			this.table_data_list = []
			let date = [this.start_date]
			if (this.end_date !== "") {
				date.push(this.end_date)
			}
			else {
				date.push(this.start_date)
			}
			let type = []
			this.filter_checked.filter((checked, index) => {
				if (checked) {
					type.push(index + 1)
				}
			})
			const params = {
				floor_id: this.$route.query.id,
				date: date,
				type: type,
			}
			await this.$store.dispatch('floor/getFloorHistoryApi', params)
			if (this.floorApiStatus) {
				this.isShow = false
				if(this.floorHistoryData.data_list.length != 0) {
					Object.keys(this.floorHistoryData.data_list).forEach(key => {
						this.table_data_list.push({date: key, data: this.floorHistoryData.data_list[key]})
					})
					// テーブルの全長を計算
					// 1カラム120px計算
					let category_count = 0
					// フロアのカラム数を取得
					Object.keys(this.table_data_list[0].data[0].floor_data).filter(key => {
						if (this.table_data_list[0].data[0].floor_data[key] !== null) {
							category_count++
						}
					})
					// エリアのカラム数を取得
					this.table_data_list[0].data[0].area_data.filter((data) => {
						Object.keys(data).filter(key => {
							if (key !== 'category_id' && key !== 'name' && data[key] !== null) {
								category_count++
							}
						})

						// 表示切替用フラグも同時に設定
						this.area_shows.push(true)
						this.current_area_shows.push(true)
					})
					// 総カラム数 * 120px
					this.table_width = category_count * 120
					category_count = 0				
				} else {
					this.isNodata = true
				}
				this.isActive = true
			}
		},
		getNowDate() {
			const date = new Date()
			const d = {
				year: date.getFullYear(),
				month: date.getUTCMonth() + 1,
				day: date.getDate(),
			}
			this.start_date = d.year + "-" + d.month + "-" + d.day
			this.getFloorHistoryData()
		},
		setTemperatureCircleClass(temperature) {
            return setTemperatureCircleClass(temperature, this.mstTemperatureLabel)
        },
		roundUpTemperature(temperature) {
            return roundUpTemperature(temperature)
        },
		getCategoryName(category_id) {
            let name = ""
            this.mstCategory.filter((item) => {
                if (item.id === category_id) {
                    name = item.name
                }
            })
            return name
        },
		setColSpan(is_main, data) {
            let count = 0
            if (is_main) {
                Object.keys(data).filter(key => {
                    if (data[key] !== null) {
                        count++
                    }
                })
            }
            else {
                Object.keys(data).filter(key => {
                    if (data[key] !== null && key !== 'category_id' && key !== 'name') {
                        count++
                    }
                })
            }
            return count
        },
		pickerClosed(is_start) {
			if (is_start) {
				this.start_date = moment(this.start_date).format('YYYY-MM-DD')
			}
			else {
				this.end_date = moment(this.end_date).format('YYYY-MM-DD')
			}
			if (Math.abs(moment(this.start_date).diff(moment(this.end_date), "days")) < 7 || !this.end_date) {
				this.prev_start_date = this.start_date;
				this.prev_end_date = this.end_date;
				this.getFloorHistoryData()	
			} else {
				this.start_date = this.prev_start_date;
				this.end_date = this.prev_end_date;
				this.togglePeriodModal(true);
			}
			
		},
		changed() {
			this.getFloorHistoryData()
		},
		async getCsv() {
			let date = [this.start_date]
			if (this.end_date !== "") {
				date.push(this.end_date)
			}
			else {
				date.push(this.start_date)
			}
			let type = []
			this.filter_checked.filter((checked, index) => {
				if (checked) {
					type.push(index + 1)
				}
			})
			const params = {
				floor_id: this.$route.query.id,
				date: date,
				type: type,
			}
			await this.$store.dispatch('floor/getFloorHistoryCsvApi', params)
		},
		toggleMenu() {
			this.is_menu = !this.is_menu
		},
		save() {
			this.$store.commit('graph/setGraphShows', this.graph_shows)
			this.is_menu = !this.is_menu
		},
		cancel() {
			this.is_menu = !this.is_menu
		},
		togglePeriodModal(flag) {
			if (flag) {
				$('.deltmodal').fadeIn()
			}
			else {
				$('.deltmodal').fadeOut()
			}
		},
	},
	computed: {
		...mapState({
			mstCategory: state => state.master.mstCategory,
			mstTemperatureLabel: state => state.master.mstTemperatureLabel,
			floorApiStatus: state => state.floor.apiStatus,
			floorHistoryData: state => state.floor.floorHistoryData,
		}),
		language(){
            return ja;
        }
	},
	watch: {
		'$route' () {
			this.floor = Object.keys(floor_ids)[Object.values(floor_ids).indexOf(this.$route.query.id)]
			this.table_data_list.splice(0, this.table_data_list.length)
			this.$store.commit('floor/setFloorHistoryData', null)
			this.getFloorHistoryData()
		},
		graph_shows() {
			this.$store.commit('graph/setGraphShows', this.graph_shows)
		}
	}
}
</script>

<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/datepicker3.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/fp.css"></style>
<style scoped src="../assets/css/toastr.css"></style>
<style scoped src="../assets/css/neumorphism.css"></style>
<style scoped src="../assets/css/report.css"></style>

<style scoped>
.col-sm-9.col-sm-offset-3.col-lg-10.col-lg-offset-2.main {
	width: 88%;
	height: 1020px;
	margin-left: 12%;
}

.report-contents {
	height: 720px;
	overflow: auto;
}

.report-item {
	height: 760px;
}

.data-main img {
	box-shadow: none;
	width: auto;
	vertical-align: initial;
}

.data-main img:hover {
	background-attachment: scroll !important;
	background-clip: border-box !important;
	background-color: transparent !important;
	background-image: none !important;
	background-origin: padding-box !important;
	background-position: 0 0 !important;
	background-repeat: repeat !important;
	background-size: auto auto !important;
	box-shadow: none;
}

.floor-list {
	background-attachment: scroll !important;
	background-clip: border-box !important;
	background-color: transparent !important;
	background-image: none !important;
	background-origin: padding-box !important;
	background-position: 0 0 !important;
	background-repeat: repeat !important;
	background-size: auto auto !important;
	box-shadow: none;
}

.report-list {
	padding-left: 0px;
	margin-left: 6%;
	height: 700px;
	width: 60%;
}

/* 元々のチェックボックス（非表示） */
.mycheckbox input[type="checkbox"]{
    display: none;
}
/* チェックボックスの代わりを成すラベル */
.mycheckbox input[type="checkbox"]+label{
    display: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
    padding-left: 25px;
    padding-right: 10px;
}
/* ラベルの左に表示させる正方形のボックス□ */
.mycheckbox input[type="checkbox"]+label::before{
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    left: 0;
    top: 50%;
    border: 1px solid;
    border-color:  #585753; /* 枠の色変更 お好きな色を */
    background-color: #FFF; /* 背景の色変更 お好きな色を */
}
/* チェックが入った時のレ点 */
.mycheckbox input[type="checkbox"]:checked+label::after{
    content: "";
    position: absolute;
    display: block;
    box-sizing: border-box;
    width: 18px;
    height: 9px;
    margin-top: -9px;
    top: 50%;
    left: 3px;
    transform: rotate(-45deg);
    border-bottom: 3px solid;
    border-left: 3px solid;
    border-color:  #585753; /* チェックの色変更 お好きな色を */
}
</style>
<style>
.vdp-datepicker {
	width: 100%;
	height: 40px;
}
.vdp-datepicker input {
    margin: 0 16px;
    width: 110px;
    height: 40px;
}
input[type="text"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: transparent;
	border: none;
	border-radius: 0;
	font: inherit;
	outline: none;
}
.wrapper {
    position: relative;
    cursor: not-allowed; 
}
.wrapper:after {
    content: '';
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0;
}
table{
	min-width: 100%;
	border-right: 1px solid #cdd2db;
	border-collapse: collapse;
}
.floor-list div:first-child{
    padding-right: 0px!important;
}
</style>