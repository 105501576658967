import { getApi, OK } from '../util'

const state = {
    apiStatus: null,
    userData: null,
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setUserData(state, data) {
        state.userData = data
    },
}

const actions = {
    // ユーザーのログイン状況を確認
    async userCheckApi(context, data) {
        context.commit('setApiStatus', null)
        const response = await getApi('user_check', data)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
    // ユーザーのログイン状況を確認
    async getUserApi(context, data) {
        context.commit('setApiStatus', null)
        const response = await getApi('get_user', data)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setUserData', response.data.data)
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}