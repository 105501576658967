<template>
	<div>
		<!--/.sidebar-->
		<div class="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2 main" :style="contentHight">
			<div class="right-wrap" :style="rightWrap">
				<div class="row">
					<ol class="breadcrumb">
						<li><a href="#"><em class="fa fa-home"></em></a></li>
						<li class="active">混雑度</li>
					</ol>
				</div>
				<!--/.row-->
				<hr style="margin:unset; margin-bottom: 10px; border: 0;border-top: 1px solid #cdd2db;">

				<!--/.row-->
				<div style="height: 100%; overflow-y: scroll;padding-top: 2px;">
					<div v-for="(list, list_index) in floor_list" :key="'congestion_' + list_index" class="row">
						<div v-for="(data, index) in list" :key="'congestion_row_' + index">
							<floor-panel-component
								:floor="data.floor_data.floor"
								:temperature="Number(data.floor_data.temperature)"
								:humidity="Number(data.floor_data.humidity)"
								:index="list_index + '_' + index"
								:map_data="data.floor_data.map_image"
								:error_level="data.floor_data.error_level"
								:floor_id="data.floor_data.id"
								:map_svg_data="data.map_data"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script scoped>
import FloorPanelComponent from "./FloorPanelComponent";
import {floor_ids, sliceByNumber} from "../config/const";
import { mapState } from 'vuex';

export default {
	components: {
		FloorPanelComponent,
	},
	head: {
		title: function() {
			return {
				inner:  'Lumino - フロア一覧'
			}
		}
	},
	data() {
		return {
			hSize: 0,
			isActive: false,
			width: 0,
			height: 0,
			floor_list: [],
		}
	},
	created() {
		this.getFloorData()
	},
	mounted() {
		this.hSize = window.innerHeight;
	},
	methods: {
		async getFloorData() {
			const params = {
				floor_id_list: [
					floor_ids["B1"],
					floor_ids['1F'],
					floor_ids['2F'],
					floor_ids['3F'],
					floor_ids['4F'],
					floor_ids['20F']
				]
			}
			await this.$store.dispatch('floor/getFloorApi', params)
			if (this.floorApiStatus) {
				this.floor_list = sliceByNumber(this.floorData, 3)
			}
		},
	},
	computed: {
		...mapState({
			floorApiStatus: state => state.floor.apiStatus,
			floorData: state => state.floor.floorData,
		}),
		contentHight() {
			return {
				'--height': this.hSize + 'px'
			}
		},
		rightWrap() {
			return {
				'--height': (this.hSize - 100) + 'px'
			}
		}
	},
}
</script>

<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/datepicker3.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/fp.css"></style>
<style scoped src="../assets/css/toastr.css"></style>
<style scoped src="../assets/css/neumorphism.css"></style>

<style scoped>
.col-sm-9.col-sm-offset-3.col-lg-10.col-lg-offset-2.main {
	width: 88%;
	--height: 0px;
	height: var(--height);
	margin-left: 12%;
}

.right-wrap {
	--height: 0px;
	height: var(--height);
}

.row:last-child {
	margin-bottom: 50px;
}
</style>