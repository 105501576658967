<template>
	<div class="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2 main" :style="contentHight">
		<div class="right-wrap" :style="rightWrap">
			<div class="kanrisystmbigbx">
				<div class="knrmainbx">
					<h1>イベント情報管理</h1>
					<div class="knrinbx">
						<h2>イベント一覧</h2>
						<div class="newrgst">
							<button class="rgstbtn">
								<router-link :to="route_path.event_register" class="btn">新規登録</router-link>
							</button>
						</div>
						<div class="eventlistbx">
							<div class="evlst">
								<ul>
									<li class="imptnt">重要</li>
									<li class="evename">イベント名</li>
									<li class="evestrt">開催日時</li>
									<li class="eveend">終了日時</li>
									<li class="evebuilname">対応ビル名</li>
								</ul>
							</div>
						</div>
						<div style="height: 750px; overflow-y: auto; padding-right: 10px;">
							<template v-if="eventData !== null && eventData.length > 0">
								<div class="eventlistbx" v-for="(event, index) in eventData" :key="'event_' + index">
									<div class="evlst evcard" @click="editEvent(index)">
										<ul>
											<li class="imptnt"><span :class="event.important_flag ? 'imptactv' : 'imptno'"></span></li>
											<li class="evename" v-html="event.event_name"></li>
											<li class="evestrt" v-html="event.start_date"></li>
											<li class="eveend" v-html="event.end_date"></li>
											<li class="evebuilname" v-html="event.building_name"></li>
										</ul>
									</div>
									<div class="evedlt">
										<button class="delet" @click="toggleDeleteModal(true, index)">
											<span class="btn">削除</span>
										</button>
									</div>
								</div>
							</template>
							<template v-else>
								<div style="text-align: center; margin-top: 100px;">
									<h2>
										イベントが登録されていません
									</h2>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
			<div class="deltmodal">
				<div class="modal__bg js-modal-close"></div>
				<div class="deltmodalcon">
					<div class="dlmdlti">
						<dl>
							<dt>{{ selected_event_data !== null ? selected_event_data.event_name : "" }}</dt>
							<dd>{{ selected_event_data !== null ? selected_event_data.start_date : "" }}<span>-</span>{{ selected_event_data !== null ? selected_event_data.end_date : "" }}</dd>
						</dl>
						<ul>
							<li>{{ selected_event_data !== null ? selected_event_data.building_name : "" }}</li>
						</ul>
					</div>
					<div class="dlmdlbtn">
						<p>このイベントを削除しますか？</p>
						<dl>
							<dt>
								<button class="cnclbtn" @click="toggleDeleteModal(false)">
									<span class="btn">キャンセル</span>
								</button>
							</dt>
							<dd>
								<button class="dltbtn" @click="deleteEvent()">
									<span class="btn">削除</span>
								</button>
							</dd>
						</dl>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script scoped>
import $ from 'jquery'
import { mapState } from 'vuex'
import { route_path } from '../router'
export default {
	head: {
		title: function() {
			return {
				inner:  'Lumino - イベント'
			}
		}
	},
	data() {
		return {
			route_path: route_path,
			hSize: 0,
			selected_event_id: 0,
			selected_event_data: null,
		}
	},
    created() {
		this.getEvents()
    },
	mounted() {
		this.hSize = window.innerHeight;
	},
	methods: {
		async getEvents() {
			await this.$store.dispatch('event/getEventApi')
		},
		async deleteEvent() {
			const params = {
				event_id: this.selected_event_data.id
			}
			await this.$store.dispatch('event/deleteEventApi', params)
			if (this.eventApiStatus) {
				await this.$store.dispatch('event/getEventApi')
				if (this.eventApiStatus) {
					$('.deltmodal').fadeOut()
				}
			}
		},
		editEvent(index) {
			this.$store.commit('event/setSelectedEventIndex', index)
			this.$router.push(route_path.event_register + "?edit=true")
		},
		toggleDeleteModal(flag, event_index = 0) {
			if (flag) {
				this.selected_event_data = this.eventData[event_index]
				$('.deltmodal').fadeIn()
			}
			else {
				$('.deltmodal').fadeOut()
			}
		},
	},
	computed: {
		contentHight() {
			return {
				'--height': this.hSize + 'px'
			}
		},
		rightWrap() {
			return {
				'--height': (this.hSize - 100) + 'px'
			}
		},
		...mapState({
			eventApiStatus: state => state.event.apiStatus,
			eventData: state => state.event.eventData,
		})
	},
}
</script>

<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/neumorphism.css"></style>
<style scoped src="../assets/css/fp.css"></style>
<style scoped src="../assets/css/event.css"></style>

<style scoped>
.col-sm-9.col-sm-offset-3.col-lg-10.col-lg-offset-2.main {
	width: 88%;
	--height: 0px;
	height: var(--height);
	margin-left: 12%;
}

.right-wrap {
	--height: 0px;
	height: var(--height);
}
</style>