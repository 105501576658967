<template>
	<div class="report-graph col-xs-4">
		<template v-if="graph_data !== null">
			<div class="panel-body col-xs-12" :style="shows[0] ? 'display: block;' : 'display: none;'">
				<div class="graph-title main">フロア</div>
				<div class="env_g">
					<canvas class="main-chart" :id="'line-chart_' + index"></canvas>
				</div>
			</div>
			<template v-for="(item, key, list_index) in graph_data.floor_graph_data">
				<div v-if="key !== 'temperature' && key !== 'humidity'" class="panel-body col-xs-12" :key="'graph_' + key" :style="shows[list_index - 1] ? 'display: block;' : 'display: none;'">
					<div class="graph-title">{{ graph_labels[key] }}</div>
					<div class="env_g">
						<canvas class="main-chart" :id="'line-chart_' + key + '_' + index"></canvas>
					</div>
				</div>
			</template>
		</template>
	</div>
</template>

<script>
import { createGraph, graph_labels, GRAPH_TYPE } from '../config/graph'
import { mapState } from 'vuex';

export default {
	props: {
		index: Number,
		date: String,
	},
	data() {
		return {
			graph_data: null,
			graph_labels: graph_labels,
		}
	},
	created() {
		this.getGraphData()
	},
	methods: {
		async getGraphData() {
			const params = {
				floor_id_list: [this.$route.query.id],
				date_list: [this.date, this.date] // 同じ日付を二つ入れないとAPIエラーになるため
			}
			await this.$store.dispatch('graph/getGraphApi', params)
			if (this.graphApiStatus) {
				Object.keys(this.graphData).forEach((key) => {
					this.graph_data = this.graphData[key]
				})
				this.$nextTick(() => {
					this.setGraphs(this.graph_data)
				})
			}
		},
		setGraphs(data) {
			const times = data.time
			let temperature_datas = [[], []]
			let graph_datas = [[]]
			// 温度、湿度、CO2濃度データ
			temperature_datas[0] = data.floor_graph_data.temperature
			temperature_datas[1] = data.floor_graph_data.humidity

			// 温度、湿度、CO2濃度グラフ作成
			createGraph(GRAPH_TYPE.temperature_humidity, document.getElementById("line-chart_" + this.index), temperature_datas, times, ["温度", "湿度"])

			// フロアの温度、湿度、CO2濃度以外
			Object.keys(data.floor_graph_data).filter(key => {
				graph_datas[0] = data.floor_graph_data[key]
				// CO2濃度
				if (key === 'co2_concentration') {
					createGraph(GRAPH_TYPE.co2, document.getElementById("line-chart_co2_concentration_" + this.index), graph_datas, times, ["CO2濃度"])
				}
				// 滞留人数
				else if (key === 'count') {
					createGraph(GRAPH_TYPE.count, document.getElementById("line-chart_count_" + this.index), graph_datas, times, ["滞留人数"])
				}
				// 照度
				else if (key === 'illuminance') {
					createGraph(GRAPH_TYPE.illuminance, document.getElementById("line-chart_illuminance_" + this.index), graph_datas, times, ["照度"])
				}
				// 騒音
				else if (key === 'noise') {
					createGraph(GRAPH_TYPE.noise, document.getElementById("line-chart_noise_" + this.index), graph_datas, times, ["騒音"])
				}
				// 総揮発性有機化学物量相当値
				else if (key === 'equivalent') {
					createGraph(GRAPH_TYPE.equivalent, document.getElementById("line-chart_equivalent_" + this.index), graph_datas, times, ["総揮発性有機化学物量相当値"])
				}
				// 熱中症危険度指数
				else if (key === 'heat_stroke') {
					createGraph(GRAPH_TYPE.heat_stroke, document.getElementById("line-chart_heat_stroke_" + this.index), graph_datas, times, ["熱中症危険度指数"])
				}
				// 入館人数
				else if (key === 'in_count') {
					createGraph(GRAPH_TYPE.in_count, document.getElementById("line-chart_in_count_" + this.index), graph_datas, times, ["入館人数"])
				}
				// 退館人数
				else if (key === 'out_count') {
					createGraph(GRAPH_TYPE.out_count, document.getElementById("line-chart_out_count_" + this.index), graph_datas, times, ["退館人数"])
				}
			})
		},
	},
	computed: {
		...mapState({
			graphApiStatus: state => state.graph.apiStatus,
			graphData: state => state.graph.graphData,
			graphShows: state => state.graph.shows,
		}),
		shows() {
			return this.$store.getters['graph/shows']
		},
	},
	watch: {
		'$route' () {
			this.graph_data = null
		}
	},
}
</script>

<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/datepicker3.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/fp.css"></style>
<style scoped src="../assets/css/toastr.css"></style>
<style scoped src="../assets/css/neumorphism.css"></style>

<style scoped>
.report-graph {
	height: 700px;
}
</style>
