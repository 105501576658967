<template>
    <div class="col-xs-12 col-md-12" style="padding-left: 1px; padding-right: 1px;" :style="contentHight">
        <div class="panel panel-default">
            <div class="panel-body">
                <div class="floor-list" style="overflow-y: scroll; padding-bottom: 15px" :style="floorListHeight" v-if="table_width.length > 0">
                    <template v-for="(data, index) in userCountData">
                        <div :key="'table_header_' + index">
                            <h4>{{ data.floor }}</h4>
                            <table :style="{'table-layout': 'fixed', 'width': table_width[index] + 'px'}">
                                <thead>
                                    <tr>
                                        <th :colspan="setColSpan(true, data.floor_data)" class="main">フロア</th>
                                        <template v-for="(area, area_index) in data.area_data">
                                             <th :colspan="setColSpan(false, area)" :class="'room' + (area_index + 1)" :key="'room' + area_index + '-head'">{{ area.name }}</th>
                                        </template>
                                    </tr>
                                    <tr>
                                        <th v-if="data.floor_data.temperature !== null" class="main">温度</th>
                                        <th v-if="data.floor_data.humidity !== null" class="main">湿度</th>
                                        <th v-if="data.floor_data.count !== null" class="main">滞留人数</th>
                                        <th v-if="data.floor_data.illuminance !== null" class="main">照度</th>
                                        <th v-if="data.floor_data.noise !== null" class="main">騒音</th>
                                        <th v-if="data.floor_data.co2_concentration !== null" class="main">CO2濃度</th>
                                        <th v-if="data.floor_data.equivalent !== null" class="main">総揮発性有機化学物量相当値</th>
                                        <th v-if="data.floor_data.heat_stroke !== null" class="main">熱中症危険度指数</th>
                                        <th v-if="data.floor_data.in_count !== null" class="main">入館人数</th>
                                        <th v-if="data.floor_data.out_count !== null" class="main">退館人数</th>
                                        <template v-for="(area, area_index) in data.area_data">
                                            <th v-if="area.temperature !== null" :class="'room' + (area_index + 1)" :key="'room1' + area_index">温度</th>
                                            <th v-if="area.humidity !== null" :class="'room' + (area_index + 1)" :key="'room2' + area_index">湿度</th>
                                            <th v-if="area.count !== null" :class="'room' + (area_index + 1)" :key="'room3' + area_index">使用座席数</th>
                                            <th v-if="area.human_count !== null" :class="'room' + (area_index + 1)" :key="'room4' + area_index">滞留人数</th>
                                        </template>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div class="floor-list-div" :key="'table_body_' + index">
                            <table :style="{'table-layout': 'fixed', 'width': table_width[index] + 'px'}">
                                <tbody>
                                    <tr>
                                        <!-- フロア -->
                                        <td :class="setTableClass(data.floor_data.temperature)" v-if="data.floor_data.temperature !== null"><i :class="['circle', setTemperatureCircleClass(data.floor_data.temperature.data)]"></i>{{ roundUpTemperature(data.floor_data.temperature.data) }}℃</td>
                                        <td :class="setTableClass(data.floor_data.humidity)" v-if="data.floor_data.humidity !== null">{{ data.floor_data.humidity.data }}%</td>
                                        <td :class="setTableClass(data.floor_data.count)" v-if="data.floor_data.count !== null">{{ data.floor_data.count.data }}人</td>
                                        <td :class="setTableClass(data.floor_data.illuminance)" v-if="data.floor_data.illuminance !== null">{{ data.floor_data.illuminance.data }}lx</td>
                                        <td :class="setTableClass(data.floor_data.noise)" v-if="data.floor_data.noise !== null">{{ data.floor_data.noise.data }}db</td>
                                        <td :class="setTableClass(data.floor_data.co2_concentration)" v-if="data.floor_data.co2_concentration !== null">{{ data.floor_data.co2_concentration.data }}ppm</td>
                                        <td :class="setTableClass(data.floor_data.equivalent)" v-if="data.floor_data.equivalent !== null">{{ data.floor_data.equivalent.data }}ppb</td>
                                        <td :class="setTableClass(data.floor_data.heat_stroke)" v-if="data.floor_data.heat_stroke !== null">{{ data.floor_data.heat_stroke.data }}degC</td>
                                        <td :class="setTableClass(data.floor_data.in_count)" v-if="data.floor_data.in_count !== null">{{ data.floor_data.in_count.data }}人</td>
                                        <td :class="setTableClass(data.floor_data.out_count)" v-if="data.floor_data.out_count !== null">{{ data.floor_data.out_count.data }}人</td>

                                        <template v-for="(area, area_index) in data.area_data">
                                            <td :key="'area' + area_index + '_temperature'" v-if="area.temperature !== null" :class="setTableClass(area.temperature)"><i :class="['circle', setTemperatureCircleClass(area.temperature.data)]"></i>{{ roundUpTemperature(area.temperature.data) }}℃</td>
                                            <td :key="'area' + area_index + '_humidity'" v-if="area.humidity !== null" :class="setTableClass(area.humidity)">{{ area.humidity.data }}%</td>
                                            <td :key="'area' + area_index + '_count'" v-if="area.count !== null" :class="setTableClass(area.count)">{{ area.count.data }}</td>
                                            <td :key="'area' + area_index + '_human_count'" v-if="area.human_count !== null" :class="setTableClass(area.count)">{{ area.human_count.data }}人</td>
                                        </template>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script scoped>
import { mapState } from 'vuex';
import {setTemperatureCircleClass, roundUpTemperature} from "../config/const";

export default {
	data() {
		return {
            hSize: 0,
            table_width: [],
		}
	},
    created() {
        this.getFloorData()
    },
    mounted() {
        this.hSize = window.innerHeight;
    },
    methods: {
        async getFloorData() {
            await this.$store.dispatch('userCount/getUserCountApi')
            if (this.userCountApiStatus) {
                // テーブルの全長を計算
                // 1カラム120px計算
                let category_count = 0
                this.userCountData.filter((item, index) => {
                    // フロアのカラム数を取得
                    Object.keys(item.floor_data).filter(key => {
                        if (item.floor_data[key] !== null) {
                            category_count++
                        }
                    })
                    // エリアのカラム数を取得
                    item.area_data.filter((data) => {
                        Object.keys(data).filter(key => {
                            if (key !== 'category_id' && key !== 'category_id' && data[key] !== null) {
                                category_count++
                            }
                        })
                    })
                    // 総カラム数 * 120px
                    this.$set(this.table_width, index, category_count * 120)
                    category_count = 0
                })
            }
        },
        setTemperatureCircleClass(temperature) {
            return setTemperatureCircleClass(temperature, this.mstTemperatureLabel)
        },
        roundUpTemperature(temperature) {
            return roundUpTemperature(temperature)
        },
        // 閾値を上回っていた場合にabnormal or warningを返す
        setTableClass(data) {
            if (data === null) {
                return ""
            }
            let class_name = ""
            if (data.level === 1) {
                class_name = "warning"
            }
            else if (data.level === 2) {
                class_name = "abnormal"
            }
            return class_name
        },
        getCategoryName(category_id) {
            let name = ""
            this.mstCategory.filter((item) => {
                if (item.id === category_id) {
                    name = item.name
                }
            })
            return name
        },
        setColSpan(is_main, data) {
            let count = 0
            if (is_main) {
                Object.keys(data).filter(key => {
                    if (data[key] !== null) {
                        count++
                    }
                })
            }
            else {
                Object.keys(data).filter(key => {
                    if (data[key] !== null && key !== 'category_id' && key !== 'name') {
                        count++
                    }
                })
            }
            return count
        }
    },
    computed: {
		contentHight() {
			return {
				'--height': (this.hSize - 120) + 'px'
			}
		},
		floorListHeight() {
			return {
				'height': (this.hSize - 180) + 'px'
			}
		},
        ...mapState({
            mstCategory: state => state.master.mstCategory,
            mstTemperatureLabel: state => state.master.mstTemperatureLabel,
            userCountApiStatus: state => state.userCount.apiStatus,
            userCountData: state => state.userCount.userCountData,
        })
	},
}
</script>

<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/fp.css"></style>
<style scoped src="../assets/css/neumorphism.css"></style>

<style scoped>
.panel {
	box-shadow: none;
}
::-webkit-scrollbar {
    height: 7px;
}
</style>
