<template>
	<div class="list-graph-wrap" id="list-graph-wrap" :style="listGraphWrap">
		<preloader-component color="blue" :scale="0.6" v-if="isShow"/>
		<div class="row" v-if="graphApiStatus">
			<div class="col-xs-12 col-md-12" v-for="(item, graph_index) in graphData.floor_list" :key="'floor_' + graph_index">
				<template v-if="item !== undefined && item !== null">
					<div class="panel panel-default">
						<div class="panel-heading">
							環境センサ ({{ graph_index }})
						</div>
						<div class="panel-body col-xs-4">
							<div class="graph-title main">フロア</div>
							<div class="env_g">
								<canvas class="main-chart" :id="'line-chart_' + graph_index"></canvas>
							</div>
						</div>
						<template v-for="(item, index) in item.environment">
							<div v-if="index !== 'temperature' && index !== 'humidity'" class="panel-body col-xs-4" :key="'graph__' + index">
								<div class="graph-title main">{{ item.name }}</div>
								<div class="env_g">
									<canvas class="main-chart" :id="'line-chart_' + index + '_' + graph_index"></canvas>
								</div>
							</div>
						</template>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/datepicker3.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/fp.css"></style>
<style scoped src="../assets/css/toastr.css"></style>
<style scoped src="../assets/css/neumorphism.css"></style>

<script>
import { createGraph, graph_labels, GRAPH_TYPE } from '../config/graph'
import { mapState } from 'vuex';
import PreloaderComponent from "./PreloaderComponent.vue"

export default {
	components: {
		PreloaderComponent,
	},
	data() {
		return {
			hSize: 0,
			graph_labels: graph_labels,
			graph_data: [],
			isShow: true,
		}
	},
	created() {
		this.getGraphData()
	},
	mounted() {
		this.hSize = window.innerHeight;
	},
	methods: {
		async getGraphData() {
			await this.$store.dispatch('environment/getEnvironmentForGraph')
			if (this.graphApiStatus) {
				this.isShow = false //ロード中のプログレスを隠す
				Object.keys(this.graphData.floor_list).forEach(key => {
					this.setGraphs(this.graphData.floor_list[key].environment, key, this.graphData.time)
				})
			}
		},
		setGraphs(data, index, times) {
			let temperature_datas = [[], []]
			let graph_datas = [[]]
			// 温度、湿度
			temperature_datas[0] = data.temperature.data
			temperature_datas[1] = data.humidity.data
			// 温度、湿度
			createGraph(GRAPH_TYPE.temperature_humidity, document.getElementById("line-chart_" + index), temperature_datas, times, ["温度", "湿度"])

			// フロアの温度、湿度、CO2濃度以外
			Object.keys(data).filter(key => {
				graph_datas[0] = data[key].data
				// CO2濃度
				if (key === 'eco2') {
					createGraph(GRAPH_TYPE.co2, document.getElementById("line-chart_eco2_" + index), graph_datas, times, ["CO2濃度"])
				}
				// 照度
				else if (key === 'ambientlight') {
					createGraph(GRAPH_TYPE.illuminance, document.getElementById("line-chart_ambientlight_" + index), graph_datas, times, ["照度"])
				}
				// 騒音
				else if (key === 'soundnoise') {
					createGraph(GRAPH_TYPE.noise, document.getElementById("line-chart_soundnoise_" + index), graph_datas, times, ["騒音"])
				}
				// 総揮発性有機化学物量相当値
				else if (key === 'etvoc') {
					createGraph(GRAPH_TYPE.equivalent, document.getElementById("line-chart_etvoc_" + index), graph_datas, times, ["総揮発性有機化学物量相当値"])
				}
				// 熱中症危険度指数
				else if (key === 'heatstroke') {
					createGraph(GRAPH_TYPE.heat_stroke, document.getElementById("line-chart_heatstroke_" + index), graph_datas, times, ["熱中症危険度指数"])
				}
				// 総揮発性有機化学物量
				else if (key === 'etvoc') {
					createGraph(GRAPH_TYPE.etvoc, document.getElementById("line-chart_etvoc_" + index), graph_datas, times, ["総揮発性有機化学物量"])
				}
				// 気圧
				else if (key === 'pressure') {
					createGraph(GRAPH_TYPE.pressure, document.getElementById("line-chart_pressure_" + index), graph_datas, times, ["気圧"])
				}
				// 不快指数
				else if (key === 'discomfort') {
					createGraph(GRAPH_TYPE.discomfort, document.getElementById("line-chart_discomfort_" + index), graph_datas, times, ["不快指数"])
				}
				// 地震センシング
				else if (key === 'seismic') {
					createGraph(GRAPH_TYPE.seismic, document.getElementById("line-chart_seismic_" + index), graph_datas, times, ["地震センシング"])
				}
			})
		},
	},
	computed: {
		...mapState({
			graphApiStatus: state => state.environment.apiStatus,
			graphData: state => state.environment.environmentForGraph,
		}),
		listGraphWrap() {
			return {
				'--height': (this.hSize - 150) + 'px'
			}
		}
	},
}
</script>

<style scoped>
.list-graph-wrap {
	--height: 100%;
	height: var(--height);
	overflow-y: scroll;
}

.panel {
	box-shadow: none;
}
</style>