<template>
	<div class="list-graph-wrap" id="list-graph-wrap" :style="listGraphWrap">
		<div class="row">
			<div class="col-xs-12 col-md-12" v-for="(data, graph_index) in graph_data" :key="'graph_' + graph_index">
				<template v-if="data !== undefined && data !== null">
					<div class="panel panel-default">
						<div class="panel-heading">
							{{ Object.keys(floor_ids)[Object.values(floor_ids).indexOf(data.floor_id)] }}
							<div class="list-menu" style="width: 5%;">
								<router-link to="report" title="レポート" data-placement="bottom">
									<em class="fa fa-lclipboard"></em>
								</router-link>
							</div>
							<div class="env_info alarm">
								<div class="list_env_ttl">温度</div>
								<div class="list_env_val"><i :class="setTemperatureCircleClass(data.floor_graph_data.temperature[data.floor_graph_data.temperature.length - 2])"></i>{{ roundUpTemperature(data.floor_graph_data.temperature[data.floor_graph_data.temperature.length - 2]) }}℃</div>
								<div class="list_env_ttl">湿度</div>
								<div class="list_env_val">{{ data.floor_graph_data.humidity[data.floor_graph_data.humidity.length - 2] }}％</div>
							</div>
						</div>
						<div class="panel-body col-xs-4">
							<div class="graph-title main">フロア</div>
							<div class="env_g">
								<canvas class="main-chart" :id="'line-chart_' + graph_index"></canvas>
							</div>
						</div>
						<template v-for="(item, index) in data.floor_graph_data">
							<div v-if="index !== 'temperature' && index !== 'humidity'" class="panel-body col-xs-4" :key="'graph__' + index">
								<div class="graph-title main">{{ graph_labels[index] }}</div>
								<div class="env_g">
									<canvas class="main-chart" :id="'line-chart_' + index + '_' + graph_index"></canvas>
								</div>
							</div>
						</template>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/datepicker3.css"></style>
<style scoped src="../assets/css/styles.css"></style>
<style scoped src="../assets/css/fp.css"></style>
<style scoped src="../assets/css/toastr.css"></style>
<style scoped src="../assets/css/neumorphism.css"></style>

<script>
import { floor_ids, setTemperatureCircleClass, roundUpTemperature } from '../config/const';
import { createGraph, graph_labels, GRAPH_TYPE } from '../config/graph'
import { mapState } from 'vuex';

export default {
	data() {
		return {
			hSize: 0,
			graph_data: [],
			graph_labels: graph_labels,
			floor_ids: floor_ids,
			floor_id_list: [
				floor_ids["B1"],
				floor_ids['1F'],
				floor_ids['2F'],
				floor_ids['3F'],
				floor_ids['4F'],
				floor_ids['5F'],
				floor_ids['6F'],
				floor_ids['7F'],
				floor_ids['8F'],
				floor_ids['9F'],
				floor_ids['10F'],
				floor_ids['11F'],
				floor_ids['12F'],
				floor_ids['13F'],
				floor_ids['14F'],
				floor_ids['15F'],
				floor_ids['16F'],
				floor_ids['17F'],
				floor_ids['18F'],
				floor_ids['19F'],
				floor_ids['20F'],
			],
		}
	},
	created() {
		this.graph_data.length = this.floor_id_list.length
		this.floor_id_list.filter((id, index) => {
			this.getGraphData(id, index)
		})
	},
	mounted() {
		this.hSize = window.innerHeight;
	},
	methods: {
		async getGraphData(id, index) {
			const params = {
				floor_id_list: [id]
			}
			await this.$store.dispatch('graph/getGraphApi', params)
			if (this.graphApiStatus) {
				Object.keys(this.graphData).forEach((key) => {
					this.$set(this.graph_data, index, this.graphData[key])
				})
				if (this.graph_data.length === this.floor_id_list.length) {
					this.$nextTick(() => {
						this.graph_data.filter((item, index) => {
							this.setGraphs(item, index)
						})
					})
				}
			}
		},
		setGraphs(data, index) {
			const times = data.time
			let temperature_datas = [[], []]
			let graph_datas = [[]]
			// 温度、湿度データ
			temperature_datas[0] = data.floor_graph_data.temperature
			temperature_datas[1] = data.floor_graph_data.humidity

			// 温度、湿度グラフ作成
			createGraph(GRAPH_TYPE.temperature_humidity, document.getElementById("line-chart_" + index), temperature_datas, times, ["温度", "湿度"])

			// フロアの温度、湿度以外
			Object.keys(data.floor_graph_data).filter(key => {
				graph_datas[0] = data.floor_graph_data[key]
				// CO2濃度
				if (key === 'co2_concentration') {
					createGraph(GRAPH_TYPE.co2, document.getElementById("line-chart_co2_concentration_" + index), graph_datas, times, ["CO2濃度"])
				}
				// 滞留人数
				else if (key === 'count') {
					createGraph(GRAPH_TYPE.count, document.getElementById("line-chart_count_" + index), graph_datas, times, ["滞留人数"])
				}
				// 照度
				else if (key === 'illuminance') {
					createGraph(GRAPH_TYPE.illuminance, document.getElementById("line-chart_illuminance_" + index), graph_datas, times, ["照度"])
				}
				// 騒音
				else if (key === 'noise') {
					createGraph(GRAPH_TYPE.noise, document.getElementById("line-chart_noise_" + index), graph_datas, times, ["騒音"])
				}
				// 総揮発性有機化学物量相当値
				else if (key === 'equivalent') {
					createGraph(GRAPH_TYPE.equivalent, document.getElementById("line-chart_equivalent_" + index), graph_datas, times, ["総揮発性有機化学物量相当値"])
				}
				// 熱中症危険度指数
				else if (key === 'heat_stroke') {
					createGraph(GRAPH_TYPE.heat_stroke, document.getElementById("line-chart_heat_stroke_" + index), graph_datas, times, ["熱中症危険度指数"])
				}
				// 入館人数
				else if (key === 'in_count') {
					createGraph(GRAPH_TYPE.in_count, document.getElementById("line-chart_in_count_" + index), graph_datas, times, ["入館人数"])
				}
				// 退館人数
				else if (key === 'out_count') {
					createGraph(GRAPH_TYPE.out_count, document.getElementById("line-chart_out_count_" + index), graph_datas, times, ["退館人数"])
				}
			})
		},
		setTemperatureCircleClass(temperature) {
            return setTemperatureCircleClass(temperature, this.mstTemperatureLabel)
        },
		roundUpTemperature(temperature) {
            return roundUpTemperature(temperature)
        },
	},
	computed: {
		...mapState({
			graphApiStatus: state => state.graph.apiStatus,
			graphData: state => state.graph.graphData,
			mstTemperatureLabel: state => state.master.mstTemperatureLabel,
		}),
		listGraphWrap() {
			return {
				'--height': (this.hSize - 150) + 'px'
			}
		}
	},
}
</script>

<style scoped>
.list-graph-wrap {
	--height: 100%;
	height: var(--height);
	overflow-y: scroll;
}

.panel {
	box-shadow: none;
}
</style>