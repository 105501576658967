import axios from "axios"

// 接続先
const access_point = process.env.VUE_APP_API_URL

// APIバージョン
const api_version = process.env.VUE_APP_API_VERSION

export const app_host = process.env.VUE_APP_HOST

// API通信成功時ステータスコード
export const OK = 200

// KeyCloak Object
export let keyCloak = null

// KeyCloak uuid
export let keyCloak_uuid = ""

// KeyCloak name
export let keyCloak_name = ""

/*
* 各API共通処理
*/
export async function getApi(api, data = {}) {
	let response = await axios.get(access_point + 'api/' + api_version + '/' + api, headers(data)).catch(function (error) {
		return createErrorReponce(error);
	});
	return errorCheck(response)
}

export async function postApi(api, data){
	let response = await axios.post(access_point + 'api/' + api_version + '/' + api, data, headers()).catch(function (error) {
		return createErrorReponce(error);
	});
	return errorCheck(response)
}

export async function putApi(api) {
	let response = await axios.put(access_point + 'api/' + api_version + '/' + api, headers()).catch(function (error) {
		return createErrorReponce(error);
	});
	return errorCheck(response)
}

export async function deleteApi(api) {
	let response = await axios.delete(access_point + 'api/' + api_version + '/' + api, headers()).catch(function (error) {
		return createErrorReponce(error);
	});
	return errorCheck(response)
}

function errorCheck(response) {
	if (!response.error) {
		var jsonData = null
		if ('string' === typeof response.data) {
			jsonData = JSON.parse(response.data)
		}
		else {
			jsonData = response.data
		}
		if (jsonData['error'] === undefined) {
			return response
		}
		response.status = jsonData.error.code
		response.message = jsonData.error.message
		return response	
	}
	
	return response;
}

function createErrorReponce(error) {
	let response = {};
	response.data = {};
	if (error.response) {
		// リクエストが行われ、サーバーは 2xx の範囲から外れるステータスコード
		response.status = error.response.status;
		response.data.code = error.response.status;
	} else if (error.request) {
		response.status = 999;
		response.data.code = 999;
	} else {
		response.status = 999;
		response.data.code = 999;
	}

	response.message = error.response.message ? error.response.message : "エラーが発生しました。";
	response.error = true;
	return response;
}

function headers(params) {
	if (keyCloak_uuid === "") return {}

	let result = {}
	if (params) {
		result = {
			headers: {
				'X-HEADER-UUID': keyCloak_uuid
			},
			params: params
		}
	}
	else {
		result = {
			headers: {
				'X-HEADER-UUID': keyCloak_uuid
			}
		}
	}
	return result
}

/*
* KeyCloak関連処理
*/
export function setKeyCloak(kc) {
	return new Promise(function(resolve, reject) {
		try {
			keyCloak = kc
			keyCloak.loadUserInfo().then(res => {
				keyCloak_uuid = res.sub
				keyCloak_name = res.name
				resolve(keyCloak_uuid)
			})
		} catch (e) {
			reject()
		}
	})
}

// KeyCloakログアウト
export function logout() {
	keyCloak.logout()
}