import Chart from 'chart.js'

export const graph_labels = {
    'co2_concentration': 'CO2濃度',
    'count': '滞留人数',
    'illuminance': "照度",
    'noise': "騒音",
    'equivalent': "総揮発性有機化学物量相当値",
    'heat_stroke': "熱中症危険度指数",
    'in_count': "入館人数",
    'out_count': "退館人数",
    'etvoc': "総揮発性有機化学物量",
    'pressure': "気圧",
    'discomfort': "不快指数",
    'seismic': "地震センシング",
}

export const GRAPH_TYPE = {
    temperature_humidity: 0,
    co2: 1,
    count: 2,
    illuminance: 3,
    noise: 4,
    equivalent: 5,
    heat_stroke: 6,
    in_count: 7,
    out_count: 8,
    area_temperature_humidity: 9,
    area_count: 10,
    area_temperature: 11,
    area_human_count: 12,
    etvoc: 13,
    pressure: 14,
    discomfort: 15,
    seismic: 16,
}

function datasets_temperature_humidity(){
    return [
        {
            yAxisID: "y-axis-1",
            type: 'line',
            label: "温度",
            borderColor: "rgba(255,215,0,0.2)",
            backgroundColor: "rgba(255,140,0,0.5)",
            fill: false,
            data: []
        },
        {
            yAxisID: "y-axis-2",
            type: 'line',
            label: "湿度",
            fill: false,
            borderColor: "rgba(0,128,0,0.2)",
            backgroundColor: "rgba(0,100,0,0.5)",
            data: []
        }
    ]
} 

function datasets_common_bar(){
    return [
        {
            label: "フロア",
            borderColor: "#a6d4f7",
            backgroundColor: "#2F96B4",
            barPercentage: 0.5,
            categoryPercentage: 0.9,
            data: []
        }
    ]
} 

function datasets_common_line(){
    return [
        {
            label: 'フロア',
            borderColor: '#a6d4f7',
            backgroundColor: '#2F96B4',
            fill: false,
            data: [],
        },
    ]
} 

// 温度、湿度用のグラフオプション
const graph_option_temperature_humidity = {
    responsive: true,
    scales: {
        yAxes: [
            {
                id: "y-axis-1",
                type: "linear",
                position: "left",
                ticks: {
                    max: 40,
                    min: 10,
                    stepSize: 10,
                    callback: function (value) {
                        return value + '℃';
                    }
                }
            },
            {
                id: "y-axis-2",
                type: "linear",
                position: "right",
                ticks: {
                    max: 100,
                    min: 0,
                    stepSize: 10,
                    callback: function (value) {
                        return value + '%';
                    }
                }
            }
        ]
    },
    annotation: {
        annotations: [
            {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-1",
                value: "25",
                borderColor: "#F7464A",
                borderDash: [2, 2],
            }
        ]
    }
}
// CO2濃度用グラフオプション
const graph_option_co2 = {
    responsive: true,
    scales: {
        yAxes: [
            {
                id: "y-axis-1",
                ticks: {
                    max: 5000,
                    min: 0,
                    stepSize: 1000,
                    callback: function (value) {
                        return value + 'ppm';
                    }
                }
            }
        ]
    },
    annotation: {
        annotations: [
            {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-1",
                value: "800",
                borderColor: "#F7464A",
                borderDash: [2, 2],
            }
        ]
    }
}
// エリア温度用グラフオプション
const graph_option_temperature = {
    responsive: true,
    scales: {
        yAxes: [
            {
                id: "y-axis-1",
                ticks: {
                    max: 35,
                    min: 15,
                    stepSize: 10,
                    callback: function (value) {
                        return value + '℃';
                    }
                }
            }
        ]
    },
    annotation: {
        annotations: [
            {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-1",
                value: "25",
                borderColor: "#F7464A",
                borderDash: [2, 2],
            }
        ]
    }
}
// 滞留人数用グラフオプション
const graph_option_count = {
    responsive: true,
    scales: {
        yAxes: [
            {
                ticks: {
                    max: 120,
                    min: 0,
                    stepSize: 20,
                    callback: function (value) {
                        return value + '人';
                    }
                }
            }
        ]
    }
}
// 照度用グラフオプション
const graph_option_illuminance = {
    responsive: true,
    scales: {
        yAxes: [
            {
                ticks: {
                    max: 500,
                    min:0,
                    stepSize: 100,
                    callback: function (value) {
                        return value + 'lx';
                    }
                }
            }
        ]
    }
}

// 騒音用グラフオプション
const graph_option_noise = {
    responsive: true,
    scales: {
        yAxes: [
            {
                ticks: {
                    max: 100,
                    min: 0,
                    stepSize: 20,
                    callback: function (value) {
                        return value + 'db';
                    }
                }
            }
        ]
    }
}

// 総揮発性有機化学物量相当値用グラフオプション
const graph_option_equivalent = {
    responsive: true,
    scales: {
        yAxes: [
            {
                ticks: {
                    max: 5000,
                    min: 0,
                    stepSize: 1000,
                    callback: function (value) {
                        return value + 'ppm';
                    }
                }
            }
        ]
    }
}

// 熱中症危険度指数用グラフオプション
const graph_option_heat_stroke = {
    responsive: true,
    scales: {
        yAxes: [
            {
                ticks: {
                    max: 40,
                    min: 0,
                    stepSize: 5,
                    callback: function (value) {
                        return value + 'ppb';
                    }
                }
            }
        ]
    }
}

// 入退館人数用グラフオプション
const graph_option_in_out_count = {
    responsive: true,
    scales: {
        yAxes: [
            {
                ticks: {
                    max: 5000,
                    min: 0,
                    stepSize: 1000,
                    callback: function (value) {
                        return value + '人';
                    }
                }
            }
        ]
    }
}

// 総揮発性有機化学物量用グラフオプション
const graph_option_etvoc = {
    responsive: true,
    scales: {
        yAxes: [
            {
                ticks: {
                    max: 2000,
                    min: 0,
                    stepSize: 200,
                    callback: function (value) {
                        return value + 'ppb';
                    }
                }
            }
        ]
    }
}
// 気圧用グラフオプション
const graph_option_pressure = {
    responsive: true,
    scales: {
        yAxes: [
            {
                ticks: {
                    max: 1200,
                    min: 0,
                    stepSize: 200,
                    callback: function (value) {
                        return value + 'hPa';
                    }
                }
            }
        ]
    }
}
// 不快指数用グラフオプション
const graph_option_discomfort = {
    responsive: true,
    scales: {
        yAxes: [
            {
                ticks: {
                    max: 100,
                    min: 0,
                    stepSize: 10,
                    callback: function (value) {
                        return value;
                    }
                }
            }
        ]
    }
}

// 熱中症危険度指数用グラフオプション
const graph_option_seismic = {
    responsive: true,
    scales: {
        yAxes: [
            {
                ticks: {
                    max: 100,
                    min: 0,
                    stepSize: 10,
                    callback: function (value) {
                        return value + 'Kine';
                    }
                }
            }
        ]
    }
}

export function createGraph(graph_type, object, data, time, label = ["フロア"], max = null) {
    // if (object === null) return false

    let datasets = null
    let options = null
    let type = 'line'
    switch(graph_type) {
        case GRAPH_TYPE.temperature_humidity:
        case GRAPH_TYPE.area_temperature_humidity:
            datasets = datasets_temperature_humidity()
            datasets[0].data = data[0]
            datasets[1].data = data[1]
            datasets[0].label = label[0]
            datasets[1].label = label[1]
            options = graph_option_temperature_humidity;
            break
        case GRAPH_TYPE.co2:
            datasets = datasets_common_line()
            datasets[0].data = data[0]
            datasets[0].label = label[0]
            options = graph_option_co2
            break
        case GRAPH_TYPE.count:
        case GRAPH_TYPE.area_count:{
            type = 'bar'
            datasets = datasets_common_bar()
            datasets[0].data = data[0]
            datasets[0].label = label[0]
            let maxCount = max != null ? max : Math.max.apply(null, data[0])
            let stepSize = 1;
            if(maxCount <= 10){
                stepSize = 1;
            } else if (maxCount <= 40){
                stepSize = 5;
            } else if (maxCount <= 100){
                stepSize = 10;
            } else if (maxCount <= 500) {
                stepSize = 50;
            } else if (maxCount <= 1000) {
                stepSize = 100;
            } else {
                stepSize = 1000;
            }

            graph_option_count.scales.yAxes[0].ticks.stepSize = stepSize;
            if(maxCount % stepSize != 0){
                maxCount = (Math.floor(maxCount / stepSize) + 1) * stepSize;
            }
            graph_option_count.scales.yAxes[0].ticks.max = maxCount
            options = graph_option_count
            break
        }
        case GRAPH_TYPE.illuminance:
            datasets = datasets_common_line()
            datasets[0].data = data[0]
            datasets[0].label = label[0]
            options = graph_option_illuminance
            break
        case GRAPH_TYPE.noise:
            datasets = datasets_common_line()
            datasets[0].data = data[0]
            datasets[0].label = label[0]
            options = graph_option_noise
            break
        case GRAPH_TYPE.equivalent:
            datasets = datasets_common_line()
            datasets[0].data = data[0]
            datasets[0].label = label[0]
            options = graph_option_equivalent
            break
        case GRAPH_TYPE.heat_stroke:
            datasets = datasets_common_line()
            datasets[0].data = data[0]
            datasets[0].label = label[0]
            options = graph_option_heat_stroke
            break
        case GRAPH_TYPE.in_count:
        case GRAPH_TYPE.out_count:
        case GRAPH_TYPE.area_human_count:
            type = 'bar'
            datasets = datasets_common_bar()
            datasets[0].data = data[0]
            datasets[0].label = label[0]
            options = graph_option_in_out_count
            break
        case GRAPH_TYPE.area_temperature:
            type = 'line'
            datasets = datasets_common_line()
            datasets[0].data = data[0]
            datasets[0].label = label[0]
            options = graph_option_temperature;
            break
        case GRAPH_TYPE.etvoc:
            type = 'line'
            datasets = datasets_common_line()
            datasets[0].data = data[0]
            datasets[0].label = label[0]
            options = graph_option_etvoc;
            break
        case GRAPH_TYPE.pressure:
            type = 'line'
            datasets = datasets_common_line()
            datasets[0].data = data[0]
            datasets[0].label = label[0]
            options = graph_option_pressure;
            break
        case GRAPH_TYPE.discomfort:
            type = 'line'
            datasets = datasets_common_line()
            datasets[0].data = data[0]
            datasets[0].label = label[0]
            options = graph_option_discomfort;
            break
        case GRAPH_TYPE.seismic:
            type = 'line'
            datasets = datasets_common_line()
            datasets[0].data = data[0]
            datasets[0].label = label[0]
            options = graph_option_seismic;
            break
        default:
            break
    }

    new Chart(object.getContext("2d"), {
        type: type,
        data: {
            labels: time,
            datasets: datasets,
        },
        options: options
    });
}