import { getApi, OK } from '../util'
import { build_id } from '../config/const'
import { saveAs } from 'file-saver'

const state = {
    apiStatus: null,
    floorData: null,
    floorDetailData: null,
    floorHistoryData: null,
    selectedFloorId: null,
    floorDataByTime: null,
    floorDataByTimeForGraph: null,
    stayCountForList: null,
    stayCountForGraph: null,
    seatCountForList: null,
    seatCountForGraph: null,
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setFloorData(state, data) {
        state.floorData = data
    },
    setFloorDetailData(state, data) {
        state.floorDetailData = data
    },
    setFloorHistoryData(state, data) {
        state.floorHistoryData = data
    },
    setSelectedFloorId(state, data) {
        state.selectedFloorId = data
    },
    setFloorDataByTime(state, data) {
        state.floorDataByTime = data
    },
    setFloorDataByTimeForGraph(state, data) {
        state.floorDataByTimeForGraph = data
    },
    setStayCountForList(state, data) {
        state.stayCountForList = data
    },
    setStayCountForGraph(state, data) {
        state.stayCountForGraph = data
    },
    setSeatCountForList(state, data) {
        state.seatCountForList = data
    },
    setSeatCountForGraph(state, data) {
        state.seatCountForGraph = data
    },
}

const actions = {
    // フロアデータ取得
    async getFloorApi(context, data) {
        const params = {
            ...data,
            build_id: build_id,
        }
        context.commit('setApiStatus', null)
        const response = await getApi('get_floor', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setFloorData', response.data.data.floor_list)
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
    // フロア詳細データ取得
    async getFloorDetailApi(context, data) {
        const params = {
            ...data,
            build_id: build_id,
        }
        context.commit('setApiStatus', null)
        const response = await getApi('get_floor_detail', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setFloorDetailData', response.data.data)
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
    // フロア過去データ取得
    async getFloorHistoryApi(context, data) {
        const params = {
            ...data,
            build_id: build_id,
        }
        context.commit('setApiStatus', null)
        const response = await getApi('get_user_count_by_type', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setFloorHistoryData', response.data.data)
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
    // フロア過去データCSV取得
    async getFloorHistoryCsvApi(context, data) {
        const params = {
            ...data,
            build_id: build_id,
        }
        context.commit('setApiStatus', null)
        const response = await getApi('import_csv', params)
        if (response.status === OK) {
            context.commit('setApiStatus', true)
            let mineType = 'text/csv'
            const bom = new Uint8Array([0xef, 0xbb, 0xbf])
            const name = response.headers["content-disposition"]
            const blob = new Blob([bom, response.data.data.csv], { type: mineType })
            saveAs(blob, name)
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
    // フロア概算人数データ取得(リスト用)
    async getUserCountApproximateByTimeForList(context, data) {
        const params = {
            ...data,
            build_id: build_id,
        }
        context.commit('setApiStatus', null)
        const response = await getApi('get_user_count_approximate_by_time_for_list', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setFloorDataByTime', response.data.data)
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
    // フロア概算人数データ取得(グラフ用)
    async getUserCountApproximateByTimeForGraph(context, data) {
        const params = {
            ...data,
            build_id: build_id,
        }
        context.commit('setApiStatus', null)
        const response = await getApi('get_user_count_approximate_by_time_for_graph', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setFloorDataByTimeForGraph', response.data.data.floor_list)
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
    // 滞留人数データ取得(リスト用)
    async getStayCountForList(context, data) {
        const params = {
            ...data,
            build_id: build_id,
        }
        context.commit('setApiStatus', null)
        const response = await getApi('get_stay_count_for_list', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setStayCountForList', response.data.data)
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
    // 滞留人数データ取得(グラフ用)
    async getStayCountForGraph(context, data) {
        const params = {
            ...data,
            build_id: build_id,
        }
        context.commit('setApiStatus', null)
        const response = await getApi('get_stay_count_for_graph', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setStayCountForGraph', response.data.data)
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
    // 着席人数データ取得(リスト用)
    async getSeatCountForList(context, data) {
        const params = {
            ...data,
            build_id: build_id,
        }
        context.commit('setApiStatus', null)
        const response = await getApi('get_seat_count_for_list', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setSeatCountForList', response.data.data)
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
    // 着席人数データ取得(グラフ用)
    async getSeatCountForGraph(context, data) {
        const params = {
            ...data,
            build_id: build_id,
        }
        context.commit('setApiStatus', null)
        const response = await getApi('get_seat_count_for_graph', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setSeatCountForGraph', response.data.data)
            return false
        }

        if (!response.error) {
            // 共通エラー処理
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.data.code, { root: true })
            context.commit('error/setErrorMessage', response.data.data.message, { root: true })
        } else {
            context.commit('error/setApiStatus', true, { root: true })
            context.commit('error/setErrorCode', response.status, { root: true })
            context.commit('error/setErrorMessage', response.message, { root: true })
        }
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}