<template>
    <div class="col-sm-9 col-sm-offset-3 col-lg-10 col-lg-offset-2 main" :style="contentHight">
		<fullscreen :fullscreen.sync="is_fullscreen">
			<full-screen-index v-if="is_fullscreen" />
			<template v-else>
				<section>
					<div class="ubntbigbox">
						<div class="ubntbx01">
							<div class="ubntflexbx01">
								<div class="ubntbullbx">
									<div class="ubntbullin">
										<div class="ubntmainti">
											<h1>URBANNET</h1>
											<dl>
												<dt class="ubntbulhd">NAGOYA<span>nexta</span>BUILDING</dt>
												<dd>アーバンネット名古屋ネクスタビル</dd>
											</dl>
											<div class="ubntdaybx" v-if="now_date !== null">
												<div class="ubntdayear" style="width: 220px;">
													<div class="ubntyear" style="font-size: 32px; height: auto;">
														<p>{{ now_date.year.toString() }}</p>
														<p class="ubntdayline"><img src="../assets/img/day_line.svg" alt="#" /></p>
													</div>
													<div class="ubntday">
														<ul>
															<li class="ubntmth">{{ now_date.month.toString().length !== 2 ? "0" + now_date.month.toString() : now_date.month.toString() }}</li>
															<li class="ubntdayline" style="margin-top: 27px;"><img src="../assets/img/day_line.svg" alt="#" /></li>
														</ul>
														<dl>
															<dt style="font-weight: normal;">{{ now_date.day.toString().length !== 2 ? "0" + now_date.day.toString() : now_date.day.toString() }}</dt>
															<dd>({{ now_date.day_of_week }})</dd>
														</dl>
													</div>
												</div>
												<div class="ubnttime">
													<p>{{ now_date.hour.toString().length !== 2 ? "0" + now_date.hour.toString() : now_date.hour.toString() }}:{{ now_date.minutes.toString().length !== 2 ? "0" + now_date.minutes.toString() : now_date.minutes.toString() }}</p>
												</div>
											</div>
										</div>
										<div class="ubntscl" v-if="topBuildingData !== null">
											<div class="ubntcon">
												<div class="ubntiosum">
													<div class="ubntiosumtl">
														<p>ビル入退館概算人数合計</p>
													</div>
													<p>{{ topBuildingData.visitors }}<span>名</span></p>
												</div>
												<div class="ubntflrdtl">
													<div>
														<p>1F</p>
													</div>
													<div>
														<dl>
															<dt><span>入館人数</span></dt>
															<dd>{{ topBuildingData.enter_1f }}<span>名</span></dd>
														</dl>
														<dl>
															<dt><span>退館人数</span></dt>
															<dd>{{ topBuildingData.leave_1f }}<span>名</span></dd>
														</dl>
													</div>
												</div>
												<div class="ubntflrdtl">
													<div>
														<p>B1</p>
													</div>
													<div>
														<dl>
															<dt><span>入館人数</span></dt>
															<dd>{{ topBuildingData.enter_b1 }}<span>名</span></dd>
														</dl>
														<dl>
															<dt><span>退館人数</span></dt>
															<dd>{{ topBuildingData.leave_b1 }}<span>名</span></dd>
														</dl>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="ubntbulimg">
										<ul>
											<li class="floor20 base"><img src="../assets/img/floor_f20_01.png" alt="20F" /><img src="../assets/img/floor_f20_02.png" alt="20F" /></li>
											<li class="floor19 floorup base"><img src="../assets/img/floor_f19_01.png" alt="19F" /><img src="../assets/img/floor_f19_02.png" alt="19F" /></li>
											<li class="floor18 floorup base"><img src="../assets/img/floor_f18_01.png" alt="18F" /><img src="../assets/img/floor_f18_02.png" alt="18F" /></li>
											<li class="floor17 floorup base"><img src="../assets/img/floor_f17_01.png" alt="17F" /><img src="../assets/img/floor_f17_02.png" alt="17F" /></li>
											<li class="floor16 floorup base"><img src="../assets/img/floor_f16_01.png" alt="16F" /><img src="../assets/img/floor_f16_02.png" alt="16F" /></li>
											<li class="floor15 floorup base"><img src="../assets/img/floor_f15_01.png" alt="15F" /><img src="../assets/img/floor_f15_02.png" alt="15F" /></li>
											<li class="floor14 floorup base"><img src="../assets/img/floor_f14_01.png" alt="14F" /><img src="../assets/img/floor_f14_02.png" alt="14F" /></li>
											<li class="floor13 floorup base"><img src="../assets/img/floor_f13_01.png" alt="13F" /><img src="../assets/img/floor_f13_02.png" alt="13F" /></li>
											<li class="floor12 floorup base"><img src="../assets/img/floor_f12_01.png" alt="12F" /><img src="../assets/img/floor_f12_02.png" alt="12F" /></li>
											<li class="floor11 floorup base"><img src="../assets/img/floor_f11_01.png" alt="11F" /><img src="../assets/img/floor_f11_02.png" alt="11F" /></li>
											<li class="floor10 floorup base"><img src="../assets/img/floor_f10_01.png" alt="10F" /><img src="../assets/img/floor_f10_02.png" alt="10F" /></li>
											<li class="floor9 floorup base"><img src="../assets/img/floor_f9_01.png" alt="9F" /><img src="../assets/img/floor_f9_02.png" alt="9F" /></li>
											<li class="floor8 floorup base"><img src="../assets/img/floor_f8_01.png" alt="8F" /><img src="../assets/img/floor_f8_02.png" alt="8F" /></li>
											<li class="floor7 floorup base"><img src="../assets/img/floor_f7_01.png" alt="7F" /><img src="../assets/img/floor_f7_02.png" alt="7F" /></li>
											<li class="floor6 floorup base"><img src="../assets/img/floor_f6_01.png" alt="6F" /><img src="../assets/img/floor_f6_02.png" alt="6F" /></li>
											<li class="floor5 floorup skip base"><img src="../assets/img/floor_f5_01.png" alt="5F" /><img src="../assets/img/floor_f5_02.png" alt="5F" /></li>
											<li class="floor4 floorup base"><img src="../assets/img/floor_f4_01.png" alt="4F" /><img src="../assets/img/floor_f4_02.png" alt="4F" /></li>
											<li class="floor3 floorup base"><img src="../assets/img/floor_f3_01.png" alt="3F" /><img src="../assets/img/floor_f3_02.png" alt="3F" /></li>
											<li class="floor2 floorup base"><img src="../assets/img/floor_f2_01.png" alt="2F" /><img src="../assets/img/floor_f2_02.png" alt="2F" /></li>
											<li class="floor1 floorup base"><img src="../assets/img/floor_f1_01.png" alt="1F" /><img src="../assets/img/floor_f1_02.png" alt="1F" /></li>
											<li class="floorb1 floorup current"><img src="../assets/img/floor_b1_01.png" alt="B1" /><img src="../assets/img/floor_b1_02.png" alt="B1" /></li>
										</ul>
									</div>
								</div>
								<div class="bullfloorline"></div>
								<div class="ubntfloorbx" style="width: 670px; height: 646px;">
									<template v-if="topFloorData !== null">
										<div class="ubntflriconbx">
											<!-- <div class="flricnclrlistti">
												<ul>
													<li>フロア環境情報</li>
												</ul>
											</div> -->
											<div class="flricnlist">
												<div class="flricn">
													<dl>
														<span class="flricn-before" :style="{'background': topFloorData.temperature_color}" />
														<dt>フロア 温度</dt>
														<dd>{{ roundUpTemperature(topFloorData.temperature) }}℃</dd>
													</dl>
													<dl>
														<span class="flricn-before" :style="{'background': topFloorData.humidity_color}" />
														<dt>フロア 湿度</dt>
														<dd>{{ Math.round(topFloorData.humidity) }}%</dd>
													</dl>
													<!-- <dl v-for="equipment in topFloorData.equipment_list" :key="'equipment_' + equipment.name">
														<span class="flricn-before" :style="{'background': equipment.color}" />
														<template v-if="equipment.temperature !== null">
															<dt>{{ equipment.name }} 温度</dt>
															<dd>{{ roundUpTemperature(equipment.temperature) }}℃</dd>
														</template>
														<template v-if="equipment.humidity !== null">
															<dt>{{ equipment.name }} 湿度</dt>
															<dd>{{ roundUpTemperature(equipment.humidity) }}℃</dd>
														</template>
													</dl> -->
												</div>
											</div>
										</div>
										<div class="ubntfloortp">
											<div class="ubntfloormap">
												<dl>
													<dt>B1</dt>
													<dd>
														<object id="svg" :data="topFloorData.image" type="image/svg+xml" style="width: 100%" />
													</dd>
												</dl>
												<div class="bubblebx">
													<div class="bubbleinner">
														<div v-for="(area, area_index) in topFloorData.equipment_list" :key="'bubble_' + area_index" class="bbl" :class="getAreaClass(area.id)">
															<ul>
																<li><img src="../assets/img/sw_shitudo.svg"><span>{{ Math.round(area.humidity) }}</span>%</li>
															</ul>
															<ul>
																<li><img src="../assets/img/sw_ondo.svg"><span>{{ roundUpTemperature(area.temperature) }}</span>℃</li>
															</ul>
															<ul>
																<li><img src="../assets/img/sw_hito.svg"><span>{{ area.human_count }}</span>人</li>
															</ul>
														</div>
													</div>
												</div>
												<!-- <div class="switch_button">
													<input id="toggle" class="toggle_input" name="engine" type='checkbox' v-model="is_bubbles" />
													<label for="toggle" class="toggle_label" />
												</div> -->
											</div>
										</div>
									</template>
								</div>
								<div class="ubntcnbx">
									<ul>
										<li>混雑度</li>
										<li>～49%</li>
										<li>～79%</li>
										<li>～100%</li>
									</ul>
								</div>
							</div>
							<div class="ubntdaywhr">
								<div class="ubntwhrbx" v-if="topWeaterData !== null">
									<div class="ubntwhrti">
										<p>WEATHER</p>
									</div>
									<div class="whrbx">
										<div class="whrtimebx">
											<div class="whrtimeflex">
												<ul>
													<li>時刻</li>
													<li class="wtrimg"></li>
													<li>気温</li>
													<li>降水</li>
													<li>湿度</li>
												</ul>
											</div>
											<div class="whrtimeflex" v-for="(today, today_index) in topWeaterData.today_list" :key="'today_' + today_index">
												<ul>
													<li>{{ today_index.toString().split(' ')[1].split(':')[0] }}</li>
													<li class="wtrimg"><img :src="today.icon" /></li>
													<li>{{ roundUpTemperature(today.temperature) }}℃</li>
													<li>{{ today.rain }}%</li>
													<li>{{ today.humidity }}%</li>
												</ul>
											</div>
										</div>
										<div class="whrdaybx">
											<div class="whrday">
												<p>明日の天気</p>
												<div class="whrtmr">
													<div>
														<img :src="topWeaterData.tomorrow_data.icon" />
													</div>
													<ul>
														<li>{{ roundUpTemperature(topWeaterData.tomorrow_data.temperature) }}℃</li>
														<li>{{ topWeaterData.tomorrow_data.rain }}%</li>
														<li>{{ topWeaterData.tomorrow_data.humidity }}%</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="ubntinfobx">
							<div class="ubninfoti">
								<h2>EVENT INFORMATION</h2>
							</div>
							<div class="infoscrlbx">
								<div class="infoscrlin">
									<div class="infoevbx" v-for="(event, event_index) in event_list[event_page]" :key="'event_' + event_index">
										<div class="infoevtp">
											<div :class="{'infoevdt': true, 'flg': event.flag}">
												<p>{{ event.start_date + ' ' + event.start_time }} - {{ event.start_date !== event.end_date ? event.end_date + ' ' + event.end_time : '' + ' ' + event.end_time }}</p>
											</div>
											<div :class="{'infoevplc': true, 'alflr': event.flag}">
												<div>{{ event.building_name }}</div>
											</div>
											<div class="infoevdtl">
												<h3>{{ event.event_name }}</h3>
												<p>{{ event.info }}</p>
											</div>
										</div>
									</div>
								</div>
								<div class="slicksmp" v-if="event_list !== null && event_list.length > 1">
									<ul>
										<li v-for="(e_list, e_list_index) in event_list" :key="'event_list_' + e_list_index" :class="{'bl': event_page === e_list_index}"></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</section>
				<div class="fullscreenbtn" @click="showFullScreen">
					<img src="../assets/img/fullscreen_icon.svg">
				</div>
			</template>
		</fullscreen>
	</div>
</template>


<style scoped src="../assets/css/bootstrap.css"></style>
<style scoped src="../assets/css/dashboard.css"></style>
<style scoped src="../assets/css/viewer.css"></style>

<script>
import $ from 'jquery'
import { mapState } from 'vuex'
import { day_of_week, floor_ids, sliceByNumber, roundUpTemperature, area_bubbles } from '../config/const'
import FullScreenIndex from './FullScreenIndex.vue'

export default {
	components: {
		FullScreenIndex,
	},
	data() {
		return {
			hSize: 0,
			handler: null,
			con_change_event: null,
			get_floor_event: null,
			pageing_event: null,
			get_count_user: null,
			is_fullscreen: false,
			event_list: [],
			event_page: 0,
			event_max_num: 4,
			date: null,
			now_date: null,
			date_update_event: null,
			day_of_week: day_of_week,
			top_floor_list: [
				floor_ids["B1"],
				floor_ids['1F'],
				floor_ids['2F'],
				floor_ids['3F'],
				floor_ids['4F'],
				floor_ids['20F']
			],
			selected_floor_index: 0,
			floor_svg: null,
			area_bubbles: area_bubbles,
			is_bubbles: false,
		}
	},
	created() {
		this.getNowDate()
		this.getTopData()
	},
	mounted() {
		this.hSize = window.innerHeight;
		this.date_update_event = setInterval(this.getNowDate, 1000)
	},
	methods: {
		async getTopData() {
			// トップデータ取得API
			await this.$store.dispatch('top/getTopApi')
			if (this.topApiStatus) {

				if(this.get_count_user === null){
					this.event_list = sliceByNumber(this.topEventData, this.event_max_num)
				}

				if (this.get_floor_event === null) {
					// フロアデータ取得を先行して実行する
					this.getTopFloor(true)

					// フロアマップ切り替え、イベントページ切り替えを設定
					this.setEvents()
				}
			}
		},
		async getTopFloor(is_first = false) {
			// トップフロアデータ取得API
			this.$store.dispatch('top/getTopFloorApi', { floor_id: this.top_floor_list[this.selected_floor_index] })
				.then(() => {
					// 要素を取得
					const element = document.getElementById("svg")
					element.addEventListener('load', () => {
						const area_element = element.contentDocument

						this.topFloorData.map_data.filter(map_data => {
							if (map_data.area_id_list !== null) {
								// colorを設定する
								map_data.area_id_list.filter((key) => {
									if (area_element.getElementById(key) !== null) {
										area_element.getElementById(key).setAttribute('fill', map_data.color)
									}
								})
							}
						})
					})
					if (!is_first) {
						// 階層を更新
						this.floorChange()
					}
				})

			// 取得フロア切り替え
			if (this.selected_floor_index === this.top_floor_list.length - 1) {
				this.selected_floor_index = 0
			}
			else {
				this.selected_floor_index++
			}
		},
		setEvents() {
			this.get_floor_event = setInterval(this.getTopFloor, 10000)
			this.pageing_event = setInterval(this.pageingEvent, 20000)
			this.get_count_user = setInterval(this.getTopData, 60000)
		},
		pageingEvent() {
			if (this.event_list !== null) {
				if (this.event_list.length - 1 === this.event_page) {
					this.event_page = 0
				}
				else {
					this.event_page++
				}
			}
		},
		showFullScreen() {
			this.is_fullscreen = true
		},
		getNowDate() {
			this.date = new Date()
			this.now_date = {
				year: this.date.getFullYear(),
				month: this.date.getUTCMonth() + 1,
				day: this.date.getDate(),
				hour: this.date.getHours(),
				minutes: this.date.getMinutes(),
				day_of_week: this.day_of_week[this.date.getDay()]
			}
		},
		floorChange(){
			// やじるしリセット
			$('.bullfloorline').css('height', 0)
			$('.current').each(function() {
				let alt = 'B1'
				// 最上階以外の場合、上階に移動
				if($(this).hasClass('floorup')) {
					if ($(this).prev().hasClass('skip')) {
						$('.floor20').addClass('current')
						$('.floor20').addClass('base')
						alt = $('.floor20').find('img').attr('alt')
					}
					else {
						$(this).prev().addClass('current')
						$(this).prev().removeClass('base')
						alt = $(this).prev().find('img').attr('alt')
					}
				}
				// 最上階の場合、B1に戻る
				else {
					$('.floorb1').addClass('current')
					$('.floorb1').removeClass('base')
				}
				$(this).addClass('base')
				$(this).removeClass('current')
				$('.ubntfloormap dl dt').text(alt)
			})

			// やじるしのばす
			setTimeout(() => {
				let lineH = this.is_fullscreen ? $('.current img:nth-of-type(2)').offset().top - 18 : $('.current img:nth-of-type(2)').offset().top - $('.current img:nth-of-type(2)').height() - 57
				// フルスクリーンならpxが変わるため決め打ちで50px減らす
				if (this.is_fullscreen) {
					lineH -= 50
				}
				$('.bullfloorline').animate({'height': lineH})
			}, 500)
		},
		roundUpTemperature(temperature) {
			return roundUpTemperature(temperature)
		},
		getAreaClass(area_id) {
			let class_name = "bubble_none"
			if (this.area_bubbles[area_id] !== undefined) {
				class_name = this.area_bubbles[area_id]
			}
			return class_name
		},
	},
	computed: {
		...mapState({
			topApiStatus: state => state.top.apiStatus,
			topBuildingData: state => state.top.buildingData,
			topWeaterData: state => state.top.weatherData,
			topEventData: state => state.top.eventData,
			topFloorData: state => state.top.floorData,
		}),
		contentHight() {
			return {
				'--height': (this.hSize - 60) + 'px',
				'--lineHeight': (this.hSize - 200) + 'px'
			}
		},
	},
	watch: {
		is_fullscreen() {
			this.selected_floor_index = 0
			this.getTopFloor(true)
		},
		is_bubbles(val) {
			if (val) {
				$('.bubblebx').show()
			}
			else {
				$('.bubblebx').hide()
			}
		},
	},
	destroyed() {
		clearTimeout(this.handler)
		clearInterval(this.get_floor_event)
		clearInterval(this.pageing_event)
		clearInterval(this.get_count_user)
		clearInterval(this.date_update_event)
	}
}
</script>

<style scoped>
.col-sm-9.col-sm-offset-3.col-lg-10.col-lg-offset-2.main {
	width: 88%;
	margin-left: 12%;
}
.col-sm-9.col-sm-offset-3.col-lg-10.col-lg-offset-2.main section {
	background-color: white;
}
.flricn-before {
	position: absolute;
    width: 16px;
    height: 32px;
    background: #D7EBFF;
    top: -5px;
}
</style>